import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';

import * as constants from './ordersConstants';
import * as actions from './ordersActions';
import * as services from './ordersServices';

function* getOrders({ payload }) {
  try {
    const { orders } = yield services.getOrders(
      payload?.status,
      payload?.month
    );
    if (orders) yield put(actions.getOrdersSuccess({ list: orders }));
  } catch (err) {
    yield put(actions.getOrdersFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getOrdersSaga() {
  yield takeLatest(constants.GET_ORDERS_START, getOrders);
}

// get order
function* getOrder({ payload: { orderId } }) {
  try {
    const { order } = yield services.getOrder(orderId);
    if (order) yield put(actions.getOrderSuccess({ order }));
  } catch (err) {
    yield put(actions.getOrderFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getOrderSaga() {
  yield takeLatest(constants.GET_ORDER_START, getOrder);
}

// edit
function* editOrder({ payload }) {
  try {
    const { order } = yield services.editOrder(payload);
    if (order) {
      yield put(actions.editOrderSuccess());
      yield put(actions.getOrdersStart({ status: payload.pageStatus }));
      yield put(
        renderSnackbar(
          'success',
          `Se ha movido a ${
            payload.status === 'confirmed'
              ? 'Pedidos entregados'
              : payload.status === 'pending'
              ? 'Pedidos pendientes'
              : 'Pedidos canceladas'
          }`
        )
      );
    }
  } catch (err) {
    yield put(actions.editOrderFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editOrderSaga() {
  yield takeLatest(constants.EDIT_ORDER_START, editOrder);
}

// edit payment
function* editPayment({ payload }) {
  try {
    const { payment } = yield services.editPayment(payload);
    if (payment) {
      yield put(actions.editPaymentSuccess());
      yield put(actions.getOrdersStart());
      yield put(renderSnackbar('success', 'Confirmado correctamente!'));
    }
  } catch (err) {
    yield put(actions.editPaymentFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editPaymentSaga() {
  yield takeLatest(constants.EDIT_PAYMENT_START, editPayment);
}

// refund payment
function* refundPayment({ payload }) {
  try {
    const { success } = yield services.refundPayment(payload);
    if (success) {
      yield put(actions.refundPaymentSuccess());
      yield put(actions.getOrdersStart());
      yield put(
        renderSnackbar('success', 'Se le devolvió el dinero correctamente!')
      );
    }
  } catch (err) {
    yield put(actions.refundPaymentSuccess({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* refundPaymentSaga() {
  yield takeLatest(constants.REFUND_PAYMENT_START, refundPayment);
}

export default function* ordersSaga() {
  yield all([
    fork(getOrdersSaga),
    fork(getOrderSaga),
    fork(editOrderSaga),
    fork(editPaymentSaga),
    fork(refundPaymentSaga)
  ]);
}
