import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Dashboard from '../Dashboard/Dashboard';

const DashboardRoute = ({ component: Component, isManager, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Dashboard isManager={isManager} {...matchProps.match}>
          <Component {...matchProps} />
        </Dashboard>
      )}
    />
  );
};

DashboardRoute.propTypes = {
  component: PropTypes.any,
  isManager: PropTypes.bool
};

export default DashboardRoute;
