import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import GlobalStyles from '../../globalStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import DashboardRoute from './DashboardRoute';
// components
import ErrorBoundary from './ErrorBoundary';
// containers
import Login from '../Auth/Login';
import Products from '../Products/Products';
import Home from '../Home/Home';
import Categories from '../Categories/Categories';
import Orders from '../Orders/Orders';
import AddProduct from '../Products/AddProduct';
import AddCategory from '../Categories/AddCategory';
import EditCategory from '../Categories/EditCategory';
import Tags from '../Tags/Tags';
import AddTag from '../Tags/AddTag';
import EditTag from '../Tags/EditTag';
import EditProduct from '../Products/EditProduct';
import Users from '../Users/Users';
import Newsletters from '../Newsletter/Newsletter';
import MyAccount from '../Auth/MyAccount';
import Order from '../Orders/Order';
import theme from '../../theme';
import NotFound from './NotFound';
import Offers from '../Offers/Offers';
import AddOffer from '../Offers/AddOffer';
import EditOffer from '../Offers/EditOffer';
import Combos from '../Combos/Combos';
import AddCombo from '../Combos/AddCombo';
import EditCombo from '../Combos/EditCombo';
import AddUser from '../Users/AddUser';
import EditUser from '../Users/EditUser';
import Sends from '../Sends/Sends';
import AddSend from '../Sends/AddSend';
import EditSend from '../Sends/EditSend';

export default () => {
  return (
    <ErrorBoundary>
      <MuiThemeProvider theme={theme}>
        <GlobalStyles />
        <Switch>
          <Route path='/' component={Login} exact />
          <DashboardRoute
            exact
            path='/'
            component={() => <Redirect to='/products' />}
          />
          <DashboardRoute exact path='/app' component={Home} />
          <DashboardRoute exact path='/users/add' component={AddUser} />
          <DashboardRoute exact path='/users/edit/:id' component={EditUser} />
          <DashboardRoute exact path='/products' component={Products} />
          <DashboardRoute exact path='/products/add' component={AddProduct} />
          <DashboardRoute
            exact
            path='/products/edit/:id'
            component={EditProduct}
          />
          <DashboardRoute exact path='/packs' component={Combos} />
          <DashboardRoute exact path='/packs/add' component={AddCombo} />
          <DashboardRoute exact path='/packs/edit/:id' component={EditCombo} />
          <DashboardRoute exact path='/orders/confirming' component={Orders} />
          <DashboardRoute exact path='/orders/pending' component={Orders} />
          <DashboardRoute exact path='/orders/confirmed' component={Orders} />
          <DashboardRoute exact path='/orders/deleted' component={Orders} />
          <DashboardRoute exact path='/order/:id' component={Order} />

          <DashboardRoute exact path='/categories' component={Categories} />
          <DashboardRoute
            exact
            path='/categories/add'
            component={AddCategory}
          />
          <DashboardRoute
            exact
            path='/categories/edit/:id'
            component={EditCategory}
          />
          <DashboardRoute exact path='/tags' component={Tags} />
          <DashboardRoute exact path='/tags/add' component={AddTag} />
          <DashboardRoute exact path='/tags/edit/:id' component={EditTag} />
          <DashboardRoute exact path='/offers' component={Offers} />
          <DashboardRoute exact path='/offers/add' component={AddOffer} />
          <DashboardRoute exact path='/offers/edit/:id' component={EditOffer} />
          <DashboardRoute exact path='/users' component={Users} />
          <DashboardRoute exact path='/sends' component={Sends} />
          <DashboardRoute exact path='/sends/add' component={AddSend} />
          <DashboardRoute exact path='/sends/edit/:id' component={EditSend} />
          <DashboardRoute exact path='/newsletter' component={Newsletters} />
          <DashboardRoute exact path='/me' component={MyAccount} />
          <Route exact component={NotFound} />
        </Switch>
      </MuiThemeProvider>
    </ErrorBoundary>
  );
};
