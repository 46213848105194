import * as constants from './tagsConstants';

const initialState = {
  list: [],
  tag: {},
  loading: true,
  error: null,
  reqLoading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_TAGS_START:
    case constants.GET_TAG_START:
      return { ...state, loading: true, error: null };

    case constants.ADD_TAG_START:
    case constants.EDIT_TAG_START:
      return { ...state, reqLoading: true, error: null };

    case constants.GET_TAGS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };
    case constants.GET_TAG_SUCCESS:
      return { ...state, loading: false, tag: payload.tag };

    case constants.ADD_TAG_SUCCESS:
    case constants.EDIT_TAG_SUCCESS:
      return { ...state, reqLoading: false, error: null };

    case constants.GET_TAGS_FAILURE:
    case constants.GET_TAG_FAILURE:
    case constants.ADD_TAG_FAILURE:
    case constants.EDIT_TAG_FAILURE:
    case constants.DELETE_TAG_FAILURE:
      return {
        ...state,
        loading: false,
        reqLoading: false,
        error: payload.error
      };

    default:
      return state;
  }
};
