export const enumPaymentStatus = status => {
  if (status === 'confirmed') return 'Pagado';
  else if (status === 'pending') return 'Pendiente';
  else if (status === 'confirming') return 'A Confirmar';
  else if (status === 'deleted') return 'Cancelado';
  else return status;
};

export const enumOrderStatus = status => {
  if (status === 'confirmed') return 'Listo para enviar / retirar';
  else if (status === 'confirming') return 'A Confirmar';
  else if (status === 'pending') return 'En preparación';
  else if (status === 'deleted') return 'Cancelado';
  else return status;
};

export const enumNormalStatus = status => {
  if (status === 'confirmed' || status === 'true') return 'Disponible';
  else if (status === 'pending') return 'Pendiente';
  else if (status === 'deleted' || status === 'false')
    return status === 'deleted' ? 'Eliminado' : 'No disponible';

  return status;
};

export const enumMonths = () => {
  return [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];
};

export const enumOfferType = type => {
  if (type === 'fixed') return 'Fijo';
  else if (type === 'porcentage') return 'Porcentaje';
  else return type;
};

export const enumSendMode = mode => {
  if (mode.includes('Retiro')) return mode;

  if (mode.includes('full')) mode = mode.replace('full', 'Moto Express Full ');

  return mode;
};
