import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { editCategoryStart, getCategoryStart } from './categoriesActions';
import Loader from '../../components/Loader/Loader';
import renderSnackbar from '../../utils/snackbar';
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';
import { getProductsStart } from '../Products/productsActions';
import { getCombosStart } from '../Combos/combosActions';

const EditCategory = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const [productsList, setProductsList] = useState([]);
  const [combosList, setCombosList] = useState([]);

  const { list: products, loading: productsLoading } = useSelector(
    ({ products }) => products
  );
  const { list: combos, loading: combosLoading } = useSelector(
    ({ combos }) => combos
  );
  const { loading, category, reqLoading } = useSelector(
    ({ categories }) => categories
  );
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCategoryStart({ categoryId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getProductsStart());
    dispatch(getCombosStart());
  }, [dispatch]);

  useEffect(() => {
    if (category?.products?.length)
      setProductsList(category.products.map(p => p._id));
    if (category?.combos?.length)
      setCombosList(category.combos.map(c => c._id));
  }, [dispatch, category]);

  const onSubmit = data => {
    const state = {
      ...data,
      products: productsList,
      combos: combosList,
      categoryId: id
    };

    if (!data.name.trim()) {
      dispatch(renderSnackbar('error', 'Nombre requerido'));
      return false;
    }

    dispatch(editCategoryStart(state));
  };

  if (loading || productsLoading || combosLoading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} disabled={reqLoading}>
      <FormWrapper>
        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField
                label='Nombre'
                variant='filled'
                fullWidth
                disabled={reqLoading}
              />
            }
            name='name'
            control={control}
            defaultValue={category.name}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Productos</InputLabel>
            <Select
              onChange={e => setProductsList(e.target.value)}
              value={productsList || []}
              multiple
              disabled={reqLoading}
            >
              {products.map(({ _id, name, status, category }) => {
                if (status === 'confirmed')
                  return (
                    <MenuItem
                      key={_id}
                      value={_id}
                      disabled={
                        category?.status === 'confirmed' && category?._id !== id
                      }
                    >
                      {name}
                    </MenuItem>
                  );
                return null;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Packs</InputLabel>
            <Select
              onChange={e => setCombosList(e.target.value)}
              value={combosList || []}
              multiple
              disabled={reqLoading}
            >
              {combos.map(({ _id, name, status, category }) => {
                if (status === 'confirmed')
                  return (
                    <MenuItem
                      key={_id}
                      value={_id}
                      disabled={
                        category?.status === 'confirmed' && category?._id !== id
                      }
                    >
                      {name}
                    </MenuItem>
                  );
                return null;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
            disabled={reqLoading}
          >
            Guardar
          </Button>
        </Grid>
      </FormWrapper>
    </Form>
  );
};

export default EditCategory;
