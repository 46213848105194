import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addSendStart } from './sendsActions';
import renderSnackbar from '../../utils/snackbar';
import { Grid, TextField, Button } from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';

const AddSend = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const reqLoading = useSelector(({ sends }) => sends.reqLoading);

  const onSubmit = ({ name = '', price }) => {
    if (!name.trim().length) {
      dispatch(renderSnackbar('error', 'Nombre requerido'));
      return false;
    }
    if (price === null || price === undefined) {
      dispatch(renderSnackbar('error', 'Precio requerido'));
      return false;
    }
    dispatch(addSendStart({ name, price }));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} disabled={reqLoading}>
      <FormWrapper>
        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField
                label='Nombre'
                variant='filled'
                fullWidth
                disabled={reqLoading}
              />
            }
            name='name'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField
                label='Precio'
                variant='filled'
                fullWidth
                disabled={reqLoading}
              />
            }
            name='price'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
            disabled={reqLoading}
          >
            Crear
          </Button>
        </Grid>
      </FormWrapper>
    </Form>
  );
};

export default AddSend;
