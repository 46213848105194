import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCategoriesStart, deleteCategoryStart } from './categoriesActions';
// styles & components
import Table from '../../components/Basics/Table';
import TableButton from '../../components/Basics/TableButton';
import Loader from '../../components/Loader/Loader';
import { Container } from './categoriesStyles';
import { TableCell, TableRow } from '@material-ui/core';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableHeader from '../../components/Basics/TableHeader';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

const Categories = () => {
  const [value, setValue] = useState(''),
    [open, setOpen] = useState({ open: false, _id: '' });

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ categories }) => categories);

  const history = useHistory();

  useEffect(() => {
    dispatch(getCategoriesStart());
  }, [dispatch]);

  const handleChangeRoute = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  const handleDeleteCategory = useCallback(() => {
    dispatch(deleteCategoryStart({ categoryId: open._id }));
  }, [dispatch, open._id]);

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        buttonText='Categoría'
        setValue={setValue}
        onChangeRoute={() => handleChangeRoute('/categories/add')}
        dispatchTo='categories'
      />
      <Container>
        <Table columns={TABLE_OPTIONS.categories}>
          {list.map(({ _id, name, products, combos, createdAt, updatedAt }) => (
            <Fragment key={_id}>
              {name.toLowerCase().includes(value.toLowerCase()) && (
                <TableRow>
                  <TableCell align='center'>{name}</TableCell>
                  <TableCell align='center'>
                    {products.length
                      ? products.map(p => p.name).join(', ')
                      : '-'}
                  </TableCell>
                  <TableCell align='center'>
                    {combos.length ? combos.map(c => c.name).join(', ') : '-'}
                  </TableCell>
                  <TableDate date={createdAt} />
                  <TableDate date={updatedAt} />
                  <TableButton
                    handleClick={() =>
                      handleChangeRoute(`/categories/edit/${_id}`)
                    }
                    edit
                  />
                  <TableButton
                    handleClick={() => setOpen({ open: true, _id })}
                    edit={false}
                  />
                </TableRow>
              )}
            </Fragment>
          ))}
        </Table>
      </Container>
      <ConfirmDialog
        open={open.open}
        title='Eliminar Categoría'
        description='¿Desea eliminar esta categoría de la plataforma?'
        handleAccept={handleDeleteCategory}
        handleClose={() => setOpen({ open: false, _id: '' })}
      />
    </>
  );
};

export default Categories;
