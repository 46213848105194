import * as constants from './offersConstants';

const initialState = {
  list: [],
  offer: {},
  loading: true,
  reqLoading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_OFFERS_START:
    case constants.GET_OFFER_START:
      return { ...state, offer: {}, loading: true, error: null };

    case constants.ADD_OFFER_START:
    case constants.EDIT_OFFER_START:
      return { ...state, reqLoading: true };

    case constants.GET_OFFERS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };

    case constants.GET_OFFER_SUCCESS:
      return {
        ...state,
        offer: payload.offer,
        loading: false,
        error: null
      };

    case constants.ADD_OFFER_SUCCESS:
    case constants.EDIT_OFFER_SUCCESS:
      return { ...state, reqLoading: false };

    case constants.GET_OFFERS_FAILURE:
    case constants.GET_OFFER_FAILURE:
    case constants.ADD_OFFER_FAILURE:
    case constants.EDIT_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        reqLoading: false,
        error: payload.error
      };

    default:
      return state;
  }
};
