import * as constants from './categoriesConstants';

export const getCategoriesStart = payload => ({
  type: constants.GET_CATEGORIES_START,
  payload
});

export const getCategoriesSuccess = payload => ({
  type: constants.GET_CATEGORIES_SUCCESS,
  payload
});

export const getCategoriesFailure = payload => ({
  type: constants.GET_CATEGORIES_FAILURE,
  payload
});

// get category
export const getCategoryStart = payload => ({
  type: constants.GET_CATEGORY_START,
  payload
});

export const getCategorySuccess = payload => ({
  type: constants.GET_CATEGORY_SUCCESS,
  payload
});

export const getCategoryFailure = payload => ({
  type: constants.GET_CATEGORY_FAILURE,
  payload
});

// Add category
export const addCategoryStart = payload => ({
  type: constants.ADD_CATEGORY_START,
  payload
});

export const addCategorySuccess = payload => ({
  type: constants.ADD_CATEGORY_SUCCESS,
  payload
});

export const addCategoryFailure = payload => ({
  type: constants.ADD_CATEGORY_FAILURE,
  payload
});

// Edit category
export const editCategoryStart = payload => ({
  type: constants.EDIT_CATEGORY_START,
  payload
});

export const editCategorySuccess = payload => ({
  type: constants.EDIT_CATEGORY_SUCCESS,
  payload
});

export const editCategoryFailure = payload => ({
  type: constants.EDIT_CATEGORY_FAILURE,
  payload
});

// Delete category
export const deleteCategoryStart = payload => ({
  type: constants.DELETE_CATEGORY_START,
  payload
});

export const deleteCategorySuccess = payload => ({
  type: constants.DELETE_CATEGORY_SUCCESS,
  payload
});

export const deleteCategoryFailure = payload => ({
  type: constants.DELETE_CATEGORY_FAILURE,
  payload
});
