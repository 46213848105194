import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';
import { push } from 'connected-react-router';

import * as constants from './productsConstants';
import * as actions from './productsActions';
import * as services from './productsServices';

function* getProducts({ payload }) {
  try {
    const { products } = yield services.getProducts(payload?.month);
    if (products) yield put(actions.getProductsSuccess({ list: products }));
  } catch (err) {
    yield put(actions.getProductsFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getProductsSaga() {
  yield takeLatest(constants.GET_PRODUCTS_START, getProducts);
}

// Get one product
function* getProduct({ payload: { productId } }) {
  try {
    const { product } = yield services.getProduct(productId);
    if (product) yield put(actions.getProductSuccess({ product }));
  } catch (err) {
    yield put(actions.getProductFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getProductSaga() {
  yield takeLatest(constants.GET_PRODUCT_START, getProduct);
}

// Add Product
function* addProduct({ payload }) {
  try {
    const { product } = yield services.addProduct(payload);
    if (product) {
      yield put(actions.addProductSuccess());
      yield put(renderSnackbar('success', 'Creado correctamente!'));
      yield put(push('/products'));
    }
  } catch (err) {
    yield put(actions.addProductFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* addProductSaga() {
  yield takeLatest(constants.ADD_PRODUCT_START, addProduct);
}

// Edit product
function* editProduct({ payload }) {
  try {
    const { product } = yield services.editProduct(payload);
    if (product) {
      yield put(actions.editProductSuccess());
      yield put(renderSnackbar('success', 'Editado correctamente!'));
      if (payload?.onlyStatus) yield put(actions.getProductsStart());
      else yield put(push('/products'));
    }
  } catch (err) {
    yield put(actions.editProductFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editProductSaga() {
  yield takeLatest(constants.EDIT_PRODUCT_START, editProduct);
}

// Delete
function* deleteProduct({ payload }) {
  try {
    const { success } = yield services.deleteProduct(payload);
    if (success) {
      yield put(actions.deleteProductSuccess());
      yield put(actions.getProductsStart());
    }
  } catch (err) {
    yield put(actions.deleteProductFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* deleteProductSaga() {
  yield takeLatest(constants.DELETE_PRODUCT_START, deleteProduct);
}

export default function* productsSaga() {
  yield all([
    fork(getProductsSaga),
    fork(getProductSaga),
    fork(addProductSaga),
    fork(editProductSaga),
    fork(deleteProductSaga)
  ]);
}
