import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';

export default createMuiTheme({
  palette: {
    primary: teal,
    secondary: amber
  },
  typography: {
    useNextVariants: true
  }
});
