export const TABLE_OPTIONS = {
  products: [
    'Nombre',
    'Precio',
    'Oferta',
    'Categoría',
    'Etiquetas',
    'Likes',
    'Status',
    'Stock',
    'Creado',
    'Editado'
  ],
  combos: [
    'Nombre',
    'Precio',
    'Productos',
    'Categoría',
    'Etiquetas',
    'Oferta',
    'Status',
    'Stock',
    'Creado',
    'Editado'
  ],
  categories: ['Nombre', 'Productos', 'Packs', 'Creado', 'Editado'],
  tags: ['Nombre', 'Productos', 'Packs', 'Creado', 'Editado'],
  sends: ['Nombre', 'Precio', 'Creado', 'Editado'],
  users: ['Nombre', 'Rol', 'Status', 'Creado', 'Ingresó por última vez'],
  newsletter: ['Email', 'Suscrito desde'],
  orders: [
    'Código',
    'Productos',
    'Status',
    'Pago',
    'Precio',
    'Usuario',
    'Modo',
    'Creado'
  ],
  offers: [
    'Nombre',
    'Monto',
    'Tipo',
    'Productos',
    'Packs',
    'Fecha de comienzo',
    'Fecha de expiración',
    'Status',
    'Creado',
    'Editado'
  ]
};

export const translateStatus = status => {
  if (status === 'confirmed' || status === 'true') return 'Disponible';
  else if (status === 'pending') return 'Pendiente';
  else if (status === 'deleted' || status === 'false')
    return status === 'deleted' ? 'Eliminado' : 'No disponible';

  return status;
};
