import api from '../../utils/api';

export const getTags = month => {
  let requestURL = '/tags';
  if (month) requestURL += `?month=${month}`;

  return api(requestURL);
};
export const getTag = tagId => api(`/tags?tagId=${tagId}`);
export const addTag = data => api('/tags', 'POST', data);
export const editTag = data => api('/tags', 'PUT', data);
export const deleteTag = data => api('/tags', 'DELETE', data);
