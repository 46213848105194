import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import renderSnackbar from '../../utils/snackbar';

import * as constants from './categoriesConstants';
import * as actions from './categoriesActions';
import * as services from './categoriesServices';

function* getCategories({ payload }) {
  try {
    const { categories } = yield services.getCategories(payload?.month);
    if (categories)
      yield put(actions.getCategoriesSuccess({ list: categories }));
  } catch (err) {
    yield put(actions.getCategoriesFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getCategoriesSaga() {
  yield takeLatest(constants.GET_CATEGORIES_START, getCategories);
}

// get category
function* getCategory({ payload: { categoryId } }) {
  try {
    const { category } = yield services.getCategory(categoryId);
    if (category) yield put(actions.getCategorySuccess({ category }));
  } catch (err) {
    yield put(actions.getCategoryFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getCategorySaga() {
  yield takeLatest(constants.GET_CATEGORY_START, getCategory);
}

// Add Category
function* addCategory({ payload }) {
  try {
    const { category } = yield services.addCategory(payload);
    if (category) {
      yield put(actions.addCategorySuccess());
      yield put(renderSnackbar('success', 'Agregado correctamente!'));
      yield put(push('/categories'));
    }
  } catch (err) {
    yield put(actions.addCategoryFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* addCategorySaga() {
  yield takeLatest(constants.ADD_CATEGORY_START, addCategory);
}

// Edit Category
function* editCategory({ payload }) {
  try {
    const { category } = yield services.editCategory(payload);
    if (category) {
      yield put(actions.editCategorySuccess());
      yield put(renderSnackbar('success', 'Editado correctamente!'));
      yield put(push('/categories'));
    }
  } catch (err) {
    yield put(actions.editCategoryFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editCategorySaga() {
  yield takeLatest(constants.EDIT_CATEGORY_START, editCategory);
}

// Delete Category
function* deleteCategory({ payload }) {
  try {
    const { success } = yield services.deleteCategory(payload);
    if (success) {
      yield put(actions.editCategorySuccess());
      yield put(renderSnackbar('success', 'Eliminado correctamente!'));
      yield put(actions.getCategoriesStart());
    }
  } catch (err) {
    yield put(actions.editCategoryFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* deleteCategorySaga() {
  yield takeLatest(constants.DELETE_CATEGORY_START, deleteCategory);
}

export default function* categoriesSaga() {
  yield all([
    fork(getCategoriesSaga),
    fork(getCategorySaga),
    fork(addCategorySaga),
    fork(editCategorySaga),
    fork(deleteCategorySaga)
  ]);
}
