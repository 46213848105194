import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';
import { push } from 'connected-react-router';

import * as constants from './authConstants';
import * as actions from './authActions';
import * as services from './authServices';

// login
function* login({ payload }) {
  try {
    const { user, token } = yield services.login(payload);
    if (user && token) {
      localStorage.setItem('token', token);
      yield put(actions.loginSuccess({ user }));
      yield put(push('/app'));
    }
  } catch (err) {
    yield put(actions.loginFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* loginSaga() {
  yield takeLatest(constants.LOGIN_START, login);
}

// editMyAccount
function* editMyAccount({ payload }) {
  try {
    const { user } = yield services.editMyAccount(payload);
    if (user) {
      yield put(actions.editMyAccountSuccess({ user }));
      yield put(renderSnackbar('success', 'Editado correctamente!'));
    }
  } catch (err) {
    yield put(actions.editMyAccountFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editMyAccountSaga() {
  yield takeLatest(constants.EDIT_MY_ACCOUNT_START, editMyAccount);
}

export default function* Saga() {
  yield all([fork(loginSaga), fork(editMyAccountSaga)]);
}
