import styled from 'styled-components';
import { mixins } from '../../utils/styles';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.main`
  flex-grow: 1;
  padding: 24px;
  margin-left: ${props => (props.open ? '260px' : '56px')};
  ${props => mixins.transition('margin-left', props.open)};
`;

export const NavBarSpacer = styled.div`
  ${mixins.toolbar}
`;
