import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getComboStart, editComboStart } from './combosActions';
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  InputLabel
} from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';
import Loader from '../../components/Loader/Loader';
import ProductImagesUpload from '../../components/ProductImagesUpload/ProductImagesUpload';
import renderSnackbar from '../../utils/snackbar';
import { validateCombo } from './combosValidations';
import { getProductsStart } from '../Products/productsActions';

const useStyles = makeStyles({
  root: {
    background: '#f50057',
    '&:hover': {
      background: '#f50057'
    },
    color: '#fff'
  }
});

const EditCombo = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const [open, setOpen] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [stock, setStock] = useState('');

  const classes = useStyles();

  const [image, setImage] = useState({
    file0: {},
    preview0: {},
    file1: {},
    preview1: {},
    file2: {},
    preview2: {},
    file3: {},
    preview3: {},
    file4: {},
    preview4: {},
    file5: {},
    preview5: {}
  });

  const { combo, loading, reqLoading } = useSelector(({ combos }) => combos);
  const { list: products, loading: productsLoading } = useSelector(
    ({ products }) => products
  );
  const { id } = useParams();

  useEffect(() => {
    dispatch(getComboStart({ comboId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getProductsStart());
  }, [dispatch]);

  useEffect(() => {
    if (combo) {
      if (Object.keys(combo).length) {
        setImage({
          ...image,
          file0: combo.images[0] || {},
          preview0: combo.images[0] || {},
          file1: combo.images[1] || {},
          preview1: combo.images[1] || {},
          file2: combo.images[2] || {},
          preview2: combo.images[2] || {},
          file3: combo.images[3] || {},
          preview3: combo.images[3] || {},
          file4: combo.images[4] || {},
          preview4: combo.images[4] || {},
          file5: combo.images[5] || {},
          preview5: combo.images[5] || {}
        });
        setStock(combo.stock);
        setProductsList(combo.products.map(p => p._id));
      }
    }
    // eslint-disable-next-line
  }, [dispatch, combo]);

  const onSubmit = data => {
    const state = {
      ...data,
      image0: image.file0,
      image1: image.file1,
      image2: image.file2,
      image3: image.file3,
      image4: image.file4,
      image5: image.file5,
      stock,
      comboId: id
    };

    const isValid = validateCombo(state);

    if (!isValid.valid) {
      dispatch(renderSnackbar('error', isValid.message));
      return false;
    }

    dispatch(editComboStart(state));
  };

  if (loading || productsLoading) return <Loader />;

  return (
    <>
      <ProductImagesUpload
        open={open}
        setOpen={setOpen}
        image={image}
        setImage={setImage}
      />

      <Form onSubmit={handleSubmit(onSubmit)} disabled={reqLoading}>
        <FormWrapper>
          <Grid item xs={12} sm={6}>
            <Controller
              as={
                <TextField
                  label='Nombre'
                  variant='filled'
                  fullWidth
                  disabled={reqLoading}
                />
              }
              name='name'
              control={control}
              defaultValue={combo.name}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              as={
                <TextField
                  label='Precio'
                  variant='filled'
                  type='number'
                  fullWidth
                  disabled={reqLoading}
                />
              }
              name='price'
              control={control}
              defaultValue={combo.price}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Productos</InputLabel>
              <Select
                onChange={e => setProductsList(e.target.value)}
                value={productsList || []}
                multiple
                disabled={reqLoading}
              >
                {products.map(product => (
                  <MenuItem key={product._id} value={product._id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Stock</InputLabel>
              <Select
                onChange={e => setStock(e.target.value)}
                value={stock}
                disabled={reqLoading}
              >
                <MenuItem value={true}>Disponible</MenuItem>
                <MenuItem value={false}>No Disponible</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={3}>
            <Button
              type='button'
              variant='contained'
              color='primary'
              size='large'
              fullWidth
              disabled={reqLoading}
              onClick={() => setOpen(true)}
              className={classes.root}
            >
              Editar Imágenes
            </Button>
          </Grid>
          <Grid item xs={12} sm={5}></Grid>
          <Grid item xs={12} sm={4}>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              size='large'
              fullWidth
              disabled={reqLoading}
            >
              Guardar
            </Button>
          </Grid>
        </FormWrapper>
      </Form>
    </>
  );
};

export default EditCombo;
