import React from 'react';
import { TableCell } from '@material-ui/core';

import moment from 'moment-timezone/builds/moment-timezone-with-data';
moment.tz('America/Argentina/Buenos_Aires');

const TableDate = ({ date, noHour = false }) => {
  return (
    <TableCell align='center'>
      {moment(date).format('DD/MM/YY')}
      {!noHour && ' | ' + moment(date).format('hh:ss a')}
    </TableCell>
  );
};

export default React.memo(TableDate);
