import React from 'react';
import { TableCell } from '@material-ui/core';
import { enumPaymentStatus, enumNormalStatus } from '../../utils/enum';

const TableBoolean = ({ bool, rules, isPayment, isBool = false }) => {
  const del = isBool ? 'false' : 'deleted';
  return (
    <TableCell
      align='center'
      style={{
        color:
          bool === rules.match
            ? '#00796b'
            : bool === del
            ? '#d84315'
            : '#f9a825'
      }}
    >
      {isPayment ? enumPaymentStatus(bool) : enumNormalStatus(bool.toString())}
    </TableCell>
  );
};

export default React.memo(TableBoolean);
