import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addProductStart } from './productsActions';
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
  InputLabel
} from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';
import { validateProduct } from './productsValidations';
import renderSnackbar from '../../utils/snackbar';
import ProductImagesUpload from '../../components/ProductImagesUpload/ProductImagesUpload';

const useStyles = makeStyles({
  root: {
    background: '#f50057',
    '&:hover': {
      background: '#f50057'
    },
    color: '#fff'
  }
});

const AddProduct = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [stock, setStock] = useState('');
  const [image, setImage] = useState({
    file0: {},
    preview0: {},
    file1: {},
    preview1: {},
    file2: {},
    preview2: {},
    file3: {},
    preview3: {},
    file4: {},
    preview4: {},
    file5: {},
    preview5: {}
  });

  const reqLoading = useSelector(({ products }) => products.reqLoading);

  const onSubmit = data => {
    const state = {
      ...data,
      stock,
      image0: image.file0,
      image1: image.file1,
      image2: image.file2,
      image3: image.file3,
      image4: image.file4,
      image5: image.file5
    };

    const isValid = validateProduct(state);

    if (!isValid.valid) {
      dispatch(renderSnackbar('error', isValid.message));
      return false;
    }

    dispatch(addProductStart(state));
  };

  const selectedImages = Object.values(image)
    .map(l => Object.keys(l).length)
    .filter(el => el);

  return (
    <>
      <ProductImagesUpload
        open={open}
        setOpen={setOpen}
        image={image}
        setImage={setImage}
      />

      <Form onSubmit={handleSubmit(onSubmit)} disabled={reqLoading}>
        <FormWrapper>
          <Grid item xs={12} sm={6}>
            <Controller
              as={
                <TextField
                  label='Nombre'
                  variant='filled'
                  fullWidth
                  disabled={reqLoading}
                />
              }
              name='name'
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              as={
                <TextField
                  label='Precio'
                  variant='filled'
                  type='number'
                  fullWidth
                  disabled={reqLoading}
                />
              }
              name='price'
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Controller
              as={
                <TextField
                  label='Ingredientes'
                  variant='filled'
                  multiline
                  fullWidth
                  disabled={reqLoading}
                />
              }
              name='ingredients'
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Controller
              as={
                <TextField label='Uso' variant='filled' multiline fullWidth />
              }
              name='usage'
              control={control}
              disabled={reqLoading}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Controller
              as={
                <TextField
                  label='Descripción'
                  variant='filled'
                  multiline
                  fullWidth
                  disabled={reqLoading}
                />
              }
              name='description'
              control={control}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Stock</InputLabel>
              <Select
                onChange={e => setStock(e.target.value)}
                value={stock}
                disabled={reqLoading}
              >
                <MenuItem value={true}>Disponible</MenuItem>
                <MenuItem value={false}>No Disponible</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={3}>
            <Button
              type='button'
              variant='contained'
              color='primary'
              size='large'
              fullWidth
              disabled={reqLoading}
              onClick={() => setOpen(true)}
              className={classes.root}
            >
              {!selectedImages.length
                ? 'Seleccionar Imágenes'
                : `Seleccionadas ${selectedImages.length}`}
            </Button>
          </Grid>
          <Grid item xs={12} sm={5}></Grid>
          <Grid item xs={12} sm={4}>
            <Button
              type='submit'
              variant='contained'
              color='secondary'
              size='large'
              fullWidth
              disabled={reqLoading}
            >
              Crear
            </Button>
          </Grid>
        </FormWrapper>
      </Form>
    </>
  );
};

export default AddProduct;
