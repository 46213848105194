import * as constants from './notificationsConstants';

export const getNotificationsStart = payload => ({
  type: constants.GET_NOTIFICATIONS_START,
  payload
});

export const getNotificationsSuccess = payload => ({
  type: constants.GET_NOTIFICATIONS_SUCCESS,
  payload
});

export const getNotificationsFailure = payload => ({
  type: constants.GET_NOTIFICATIONS_FAILURE,
  payload
});

export const readNotifications = payload => ({
  type: constants.READ_NOTIFICATIONS,
  payload
});
