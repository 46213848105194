import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { loginStart } from './authActions';
// others
import { validateLogin } from './authValidations';
import renderSnackbar from '../../utils/snackbar';
import { replace } from 'connected-react-router';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Notifier from '../Notifier/Notifier';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    backgroundColor: '#c99b6f',
    '&:hover': {
      backgroundColor: '#c99b6f'
    },
    color: '#fff',
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function SignIn() {
  const classes = useStyles();

  const { control, handleSubmit } = useForm();

  const dispatch = useDispatch();
  const { loading, isAuth } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (isAuth) dispatch(replace('/app'));
  }, [dispatch, isAuth]);

  const onSubmit = data => {
    const isValid = validateLogin(data);
    if (!isValid.valid) {
      dispatch(renderSnackbar('error', isValid.message));
      return false;
    }
    dispatch(loginStart(data));
  };

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Ingresar ahora
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={
              <TextField
                variant='outlined'
                margin='normal'
                label='Email'
                fullWidth
                disabled={loading}
                autoComplete='email'
                autoFocus
              />
            }
            name='email'
            control={control}
          />

          <Controller
            as={
              <TextField
                variant='outlined'
                margin='normal'
                label='Contraseña'
                type='password'
                disabled={loading}
                fullWidth
              />
            }
            name='password'
            control={control}
          />

          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={loading}
          >
            Iniciar Sesión
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant='body2' color='textSecondary' align='center'>
          {'Copyright © '}
          <Link color='inherit' href='https://viemystiq.com' target='_blank'>
            Mystiq
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
      <Notifier />
    </Container>
  );
}
