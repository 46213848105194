import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addTagStart } from './tagsActions';
import renderSnackbar from '../../utils/snackbar';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';
import { getProductsStart } from '../Products/productsActions';
import { getCombosStart } from '../Combos/combosActions';
import Loader from '../../components/Loader/Loader';

const AddTag = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();
  const [productsList, setProductsList] = useState([]);
  const [combosList, setCombosList] = useState([]);

  const reqLoading = useSelector(({ tags }) => tags.reqLoading);

  const { list: products, loading } = useSelector(({ products }) => products);
  const { list: combos, loading: combosLoading } = useSelector(
    ({ combos }) => combos
  );

  useEffect(() => {
    dispatch(getProductsStart());
    dispatch(getCombosStart());
  }, [dispatch]);

  const onSubmit = ({ name = '' }) => {
    if (!name.trim().length) {
      dispatch(renderSnackbar('error', 'Nombre requerido'));
      return false;
    }
    if (!productsList.length && !combosList.length) {
      dispatch(renderSnackbar('error', 'Productos o Packs requeridos'));
      return false;
    }
    dispatch(addTagStart({ name, products: productsList, combos: combosList }));
  };

  if (loading || combosLoading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} disabled={reqLoading}>
      <FormWrapper>
        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField
                label='Nombre'
                variant='filled'
                fullWidth
                disabled={reqLoading}
              />
            }
            name='name'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Productos</InputLabel>
            <Select
              onChange={e => setProductsList(e.target.value)}
              value={productsList}
              multiple
              disabled={reqLoading}
            >
              {products.map(({ _id, name, status }) => {
                if (status === 'confirmed')
                  return (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  );
                return null;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Packs</InputLabel>
            <Select
              onChange={e => setCombosList(e.target.value)}
              value={combosList}
              multiple
              disabled={reqLoading}
            >
              {combos.map(({ _id, name, status }) => {
                if (status === 'confirmed')
                  return (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  );
                return null;
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
            disabled={reqLoading}
          >
            Crear
          </Button>
        </Grid>
      </FormWrapper>
    </Form>
  );
};

export default AddTag;
