import * as constants from './sendsConstants';

const initialState = {
  list: [],
  send: {},
  loading: true,
  reqLoading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_SENDS_START:
    case constants.GET_SEND_START:
      return { ...state, send: {}, loading: true, error: null };

    case constants.ADD_SEND_START:
    case constants.EDIT_SEND_START:
      return { ...state, reqLoading: true };

    case constants.GET_SENDS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };

    case constants.GET_SEND_SUCCESS:
      return {
        ...state,
        send: payload.send,
        loading: false,
        error: null
      };

    case constants.ADD_SEND_SUCCESS:
    case constants.EDIT_SEND_SUCCESS:
      return { ...state, reqLoading: false };

    case constants.GET_SENDS_FAILURE:
    case constants.GET_SEND_FAILURE:
    case constants.ADD_SEND_FAILURE:
    case constants.EDIT_SEND_FAILURE:
    case constants.DELETE_SEND_FAILURE:
      return {
        ...state,
        reqLoading: false,
        loading: false,
        error: payload.error
      };

    default:
      return state;
  }
};
