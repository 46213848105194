export const validateOffer = ({
  name = '',
  type = '',
  amount = '',
  products = [],
  combos = [],
  startDate = '',
  expiredDate = ''
}) => {
  if (!name.trim()) return { message: 'Nombre requerido' };
  if (!type.trim()) return { message: 'Tipo requerido' };
  if (amount === null || amount === undefined)
    return { message: 'Monto requerido' };
  else {
    let amountInt = parseInt(amount);
    if (type === 'porcentage' && amountInt > 99)
      return { message: 'El tipo porcentaje no puede superar 99%' };
  }
  if (!products.length && !combos.length)
    return { message: 'Productos o Combos requeridos' };
  if (!startDate) return { message: 'Fecha de inicio requerido' };
  if (!expiredDate) return { message: 'Fecha de expiración requerido' };
  return { valid: true };
};
