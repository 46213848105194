import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './sendsConstants';
import * as actions from './sendsActions';
import * as services from './sendsServices';
import { push } from 'connected-react-router';

function* getSends() {
  try {
    const { sends } = yield services.getSends();
    if (sends) yield put(actions.getSendsSuccess({ list: sends }));
  } catch (err) {
    yield put(actions.getSendsFailure({ error: err.message }));
  }
}

function* getSendsSaga() {
  yield takeLatest(constants.GET_SENDS_START, getSends);
}

// One send
function* getSend({ payload: { sendId } }) {
  try {
    const { send } = yield services.getSend(sendId);
    if (send) yield put(actions.getSendSuccess({ send }));
  } catch (err) {
    yield put(actions.getSendFailure({ error: err.message }));
  }
}

function* getSendSaga() {
  yield takeLatest(constants.GET_SEND_START, getSend);
}

// Create Send
function* createSend({ payload }) {
  try {
    const { send } = yield services.createSend(payload);
    if (send) {
      yield put(actions.addSendSuccess());
      yield put(push('/sends'));
    }
  } catch (err) {
    yield put(actions.addSendFailure({ error: err.message }));
  }
}

function* createSendSaga() {
  yield takeLatest(constants.ADD_SEND_START, createSend);
}

// Edit
function* editSend({ payload }) {
  try {
    const { send } = yield services.editSend(payload);
    if (send) {
      yield put(actions.editSendSuccess());
      yield put(push('/sends'));
    }
  } catch (err) {
    yield put(actions.editSendFailure({ error: err.message }));
  }
}

function* editSendSaga() {
  yield takeLatest(constants.EDIT_SEND_START, editSend);
}

// Delete
function* deleteSend({ payload }) {
  try {
    const { success } = yield services.deleteSend(payload);
    if (success) {
      yield put(actions.deleteSendSuccess());
      yield put(actions.getSendsStart());
    }
  } catch (err) {
    yield put(actions.deleteSendFailure({ error: err.message }));
  }
}

function* deleteSendSaga() {
  yield takeLatest(constants.DELETE_SEND_START, deleteSend);
}

export default function* sendsSaga() {
  yield all([
    fork(getSendsSaga),
    fork(getSendSaga),
    fork(createSendSaga),
    fork(editSendSaga),
    fork(deleteSendSaga)
  ]);
}
