import api, { requestData } from '../../utils/api';

export const getCombos = month => {
  let requestURL = '/combos';

  if (month) requestURL += `?month=${month}`;

  return api(requestURL);
};
export const getCombo = comboId => api(`/combos?comboId=${comboId}`);
export const addCombo = data => requestData('/combos', 'POST', data);
export const editCombo = data => requestData('/combos', 'PUT', data);
export const deleteCombo = data => api('/combos', 'DELETE', data);
