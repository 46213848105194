import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './newsletterConstants';
import * as actions from './newsletterActions';
import * as services from './newsletterServices';

function* getNewsletter({ payload }) {
  try {
    const { subscriptions } = yield services.getNewsletter(payload?.month);

    if (subscriptions)
      yield put(actions.getNewsletterSuccess({ list: subscriptions }));
  } catch (err) {
    yield put(actions.getNewsletterFailure({ error: err.message }));
  }
}

function* getNewsletterSaga() {
  yield takeLatest(constants.GET_NEWSLETTER_START, getNewsletter);
}

export default function* newsletterSaga() {
  yield all([fork(getNewsletterSaga)]);
}
