const sideBarPaths = [
  {
    label: 'Dashboard',
    icon: 'dashboard',
    path: '/app',
    url: '/app',
    hideManager: true
  },
  {
    label: 'Productos',
    icon: 'fiber_smart_record',
    path: '/products',
    url: '/products',
    hideManager: true
  },

  {
    label: 'Pedidos',
    icon: 'receipt',
    hideManager: true,
    child: [
      {
        label: 'Pedidos nuevos',
        icon: 'timeline',
        path: '/orders/confirming',
        url: '/orders/confirming',
        hideManager: true
      },
      {
        label: 'Pedidos pendientes',
        icon: 'timelapse',
        path: '/orders/pending',
        url: '/orders/pending',
        hideManager: true
      },
      {
        label: 'Pedidos entregados',
        icon: 'donut_small',
        path: '/orders/confirmed',
        url: '/orders/confirmed',
        hideManager: true
      },
      {
        label: 'Pedidos cancelados',
        icon: 'donut_large',
        path: '/orders/deleted',
        url: '/orders/deleted',
        hideManager: true
      }
    ]
  },
  {
    label: 'Packs',
    icon: 'account_tree',
    path: '/packs',
    url: '/packs',
    hideManager: true
  },

  {
    label: 'Categorías',
    icon: 'category',
    path: '/categories',
    url: '/categories',
    hideManager: true
  },
  {
    label: 'Etiquetas',
    icon: 'label',
    path: '/tags',
    url: '/tags',
    hideManager: true
  },
  {
    label: 'Ofertas',
    icon: 'local_offer',
    path: '/offers',
    url: '/offers',
    hideManager: true
  },
  {
    label: 'Usuarios',
    icon: 'people',
    path: '/users',
    url: '/users',
    hideManager: true
  },
  {
    label: 'Envios',
    icon: 'local_shipping',
    path: '/sends',
    url: '/sends',
    hideManager: true
  },
  {
    label: 'Newsletter',
    icon: 'inbox',
    path: '/newsletter',
    url: '/newsletter',
    hideManager: false
  },
  {
    label: 'Mi Cuenta',
    icon: 'person',
    path: '/me',
    url: '/me',
    hideManager: true
  }

  /*
  {
    label: 'Estadísticas',
    icon: 'insert_chart',
    path: '/stats',
    url: '/stats',
    hideManager: true
  },

  {
    label: 'Cupones',
    icon: 'extension',
    path: '/coupons',
    url: '/coupons',
    hideManager: true
  },

  {
    label: 'Comentarios',
    icon: 'feedback',
    path: '/comments',
    url: '/comments',
    hideManager: true
  },
  {
    label: 'Preguntas',
    icon: 'help',
    path: '/questions',
    url: '/questions',
    hideManager: true
  },
  
  {
    label: 'Banner',
    icon: 'images',
    path: '/banner',
    url: '/banner',
    hideManager: false
  },
  */
];

const hidePaths = [
  {
    label: 'Agregar Usuario',
    path: '/users/add',
    fake: true
  },
  {
    label: 'Editar Usuario',
    path: '/users/edit/:id',
    fake: true
  },
  {
    label: 'Agregar Producto',
    path: '/products/add',
    fake: true
  },
  {
    label: 'Agregar Pack',
    path: '/packs/add',
    fake: true
  },
  {
    label: 'Agregar Categoría',
    path: '/categories/add',
    fake: true
  },
  {
    label: 'Agregar Etiqueta',
    path: '/tags/add',
    fake: true
  },
  {
    label: 'Agregar Oferta',
    path: '/offers/add',
    fake: true
  },
  {
    label: 'Editar Oferta',
    path: '/offers/edit/:id',
    fake: true
  },
  {
    label: 'Editar Producto',
    path: '/products/edit/:id',
    fake: true
  },
  {
    label: 'Editar Pack',
    path: '/packs/edit/:id',
    fake: true
  },
  {
    label: 'Editar Categoría',
    path: '/categories/edit/:id',
    fake: true
  },
  {
    label: 'Editar Etiqueta',
    path: '/tags/edit/:id',
    fake: true
  },
  {
    label: 'Agregar Envio',
    path: '/sends/add',
    fake: true
  },
  {
    label: 'Editar Envio',
    path: '/sends/edit/:id',
    fake: true
  },
  {
    label: 'Orden',
    path: '/order/:id',
    fake: true
  }
];

const navigation = [...sideBarPaths, ...hidePaths];

export const searchRoute = path => {
  let route = navigation.find(n => {
    if (n.child) return n.child.find(c => c.path === path);
    return n.path === path;
  });

  if (route.child) {
    const parentLabel = route.label;
    route = route.child.find(c => c.path === path);
    return `${parentLabel} > ${route.label}`;
  }

  return route.label;
};

export const getRoute = path => {
  let route = navigation.find(n => {
    if (n?.child) return n.child.find(c => c.path === path);
    return n.path === path;
  });

  if (route?.child) {
    route = route.child.find(c => c.path === path);
    return route;
  }

  return route;
};

export default navigation;
