import React, {
  useState,
  useEffect,
  createRef,
  Fragment,
  useCallback
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getOrdersStart,
  editOrderStart,
  editPaymentStart,
  refundPaymentStart
} from './ordersActions';
// styles & components
import Loader from '../../components/Loader/Loader';
import { Container } from './ordersStyles';
import {
  TableCell,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {
  CheckCircle,
  Cancel,
  KeyboardReturn,
  Print,
  Flag
} from '@material-ui/icons';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableHeader from '../../components/Basics/TableHeader';
import Table from '../../components/Basics/Table';
import TableButton from '../../components/Basics/TableButton';
import TableBoolean from '../../components/Basics/TableBoolean';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { useSocket } from 'use-socketio';
import ReactToPrint from 'react-to-print';
import Ticket from '../../components/Ticket/Ticket';
import { enumSendMode } from '../../utils/enum';

const Orders = () => {
  const [value, setValue] = useState(''),
    [statusOpen, setStatusOpen] = useState({ open: false, _id: '' }),
    [openEditPayment, setOpenEditPayment] = useState({
      open: false,
      data: { _id: '', status: '' }
    }),
    [openRefundPayment, setOpenRefundPayment] = useState({
      open: false,
      _id: '',
      code: ''
    });
  const [refs, setRefs] = useState([]);

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ orders }) => orders);

  const { pathname } = useLocation();
  const history = useHistory();

  const status = pathname.split('/orders/')[1];

  useSocket('NEW_ORDER', () => dispatch(getOrdersStart({ status })));

  useEffect(() => {
    dispatch(getOrdersStart({ status }));
  }, [dispatch, status]);

  useEffect(() => {
    setRefs(elRefs =>
      Array(list.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [list]);

  const handleChangeStatus = useCallback(
    ({ target }) =>
      dispatch(
        editOrderStart({
          status: target.value,
          pageStatus: status,
          orderId: statusOpen._id
        })
      ),
    [dispatch, status, statusOpen._id]
  );

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        addButton={false}
        setValue={setValue}
        dispatchTo={`orders-${status}`}
      />
      <Container>
        <Table columns={TABLE_OPTIONS.orders} hasOther={2}>
          {list.map(
            ({ _id, code, payment, products, status, createdAt }, i) => (
              <Fragment key={_id}>
                {code.toLowerCase().includes(value.toLowerCase()) && (
                  <TableRow>
                    <TableCell align='center'>{code}</TableCell>
                    <TableCell align='center'>
                      {products.length
                        ? products.map(o => o?.name).join(', ')
                        : '-'}
                    </TableCell>
                    <TableCell align='center'>
                      <FormControl fullWidth>
                        <Select
                          open={statusOpen.open && statusOpen._id === _id}
                          onClose={() =>
                            setStatusOpen({ open: false, _id: '' })
                          }
                          onOpen={() => setStatusOpen({ open: true, _id })}
                          defaultValue={status}
                          onChange={e => handleChangeStatus(e, _id)}
                          inputProps={{
                            name: 'status'
                          }}
                        >
                          <MenuItem value='deleted'>Cancelado</MenuItem>
                          <MenuItem value='confirming'>A confirmar</MenuItem>
                          <MenuItem value='pending'>En Preparación</MenuItem>
                          <MenuItem value='confirmed'>
                            Listo para enviar / retirar
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableBoolean
                      bool={payment?.status}
                      rules={{ match: 'confirmed' }}
                      isPayment={true}
                    />
                    <TableCell align='center'>
                      ${payment?.price.toFixed(2)}
                    </TableCell>

                    <TableCell align='center'>
                      {payment?.user?.email || payment?.formData?.email}
                    </TableCell>

                    <TableCell align='center'>
                      {enumSendMode(payment?.formData?.sendMode)}
                    </TableCell>

                    <TableDate date={createdAt} />

                    <TableButton
                      handleClick={() => history.push(`/order/${_id}`)}
                      edit={false}
                      external={true}
                    />

                    <TableCell align='center' padding='checkbox'>
                      <Tooltip
                        title={
                          payment.status !== 'confirmed'
                            ? 'Confirmar pago'
                            : 'Cancelar pago'
                        }
                      >
                        <IconButton
                          onClick={() =>
                            setOpenEditPayment({
                              open: true,
                              data: { _id: payment._id, status: payment.status }
                            })
                          }
                        >
                          {payment.status !== 'confirmed' ? (
                            <CheckCircle fontSize='small' />
                          ) : (
                            <Cancel fontSize='small' />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell align='center' padding='checkbox'>
                      <Tooltip
                        title={
                          payment.status === 'refund'
                            ? 'El dinero fue devuelto'
                            : 'Devolver dinero'
                        }
                      >
                        <IconButton
                          onClick={() =>
                            payment.status === 'refund'
                              ? null
                              : setOpenRefundPayment({
                                  open: true,
                                  _id: payment._id
                                })
                          }
                        >
                          {payment.status === 'refund' ? (
                            <Flag />
                          ) : (
                            <KeyboardReturn />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell align='center' padding='checkbox'>
                      <Tooltip title='Imprimir ticket'>
                        <>
                          <ReactToPrint
                            trigger={() => (
                              <IconButton>
                                <Print />
                              </IconButton>
                            )}
                            content={() =>
                              refs.length ? refs[i].current : null
                            }
                          />
                          <div style={{ display: 'none' }}>
                            <Ticket
                              ref={refs.length ? refs[i] : null}
                              orderId={code}
                              name={`${payment.formData.name} ${payment.formData.surname}`}
                              location={`${payment.formData.location} - ${payment.formData.city}, ${payment.formData.street}`}
                              postalCode={payment.formData.postalCode}
                              sendMode={payment.formData.sendMode}
                              department={payment.formData.department}
                            />
                          </div>
                        </>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            )
          )}
        </Table>
      </Container>

      <ConfirmDialog
        open={openEditPayment.open}
        title={`${
          openEditPayment.data.status === 'confirmed' ? 'Cancelar' : 'Confirmar'
        } pago`}
        description='¿Desea modificar este pago?'
        handleAccept={() => {
          dispatch(
            editPaymentStart({
              status:
                openEditPayment.data.status !== 'confirmed'
                  ? 'confirmed'
                  : 'deleted',
              paymentId: openEditPayment.data._id
            })
          );
        }}
        handleClose={() =>
          setOpenEditPayment({ open: false, data: { _id: '', status: '' } })
        }
      />

      <ConfirmDialog
        open={openRefundPayment.open}
        title='Devolver el dinero'
        description='¿Desea devolver el dinero de este pago?'
        handleAccept={() =>
          dispatch(
            refundPaymentStart({
              paymentId: openRefundPayment._id,
              amount: 1
            })
          )
        }
        handleClose={() => setOpenRefundPayment({ open: false, _id: '' })}
      />
    </>
  );
};

export default Orders;
