export const GET_CATEGORIES_START = 'app/Categories/GET_CATEGORIES_START';
export const GET_CATEGORIES_SUCCESS = 'app/Categories/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'app/Categories/GET_CATEGORIES_FAILURE';

export const GET_CATEGORY_START = 'app/Categories/GET_CATEGORY_START';
export const GET_CATEGORY_SUCCESS = 'app/Categories/GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'app/Categories/GET_CATEGORY_FAILURE';

export const ADD_CATEGORY_START = 'app/Categories/ADD_CATEGORY_START';
export const ADD_CATEGORY_SUCCESS = 'app/Categories/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'app/Categories/ADD_CATEGORY_FAILURE';

export const EDIT_CATEGORY_START = 'app/Categories/EDIT_CATEGORY_START';
export const EDIT_CATEGORY_SUCCESS = 'app/Categories/EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE = 'app/Categories/EDIT_CATEGORY_FAILURE';

export const DELETE_CATEGORY_START = 'app/Categories/DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'app/Categories/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'app/Categories/DELETE_CATEGORY_FAILURE';
