import * as constants from './homeConstants';

export const getHomeStatsStart = payload => ({
  type: constants.GET_HOME_STATS_START,
  payload
});

export const getHomeStatsSuccess = payload => ({
  type: constants.GET_HOME_STATS_SUCCESS,
  payload
});

export const getHomeStatsFailure = payload => ({
  type: constants.GET_HOME_STATS_FAILURE,
  payload
});
