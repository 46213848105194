import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';
import { push } from 'connected-react-router';

import * as constants from './offersConstants';
import * as actions from './offersActions';
import * as services from './offersServices';

function* getOffers({ payload }) {
  try {
    const { offers } = yield services.getOffers(payload?.month);
    if (offers) yield put(actions.getOffersSuccess({ list: offers }));
  } catch (err) {
    yield put(actions.getOffersFailure({ error: err.message }));
  }
}

function* getOffersSaga() {
  yield takeLatest(constants.GET_OFFERS_START, getOffers);
}

// get offer
function* getOffer({ payload: { offerId } }) {
  try {
    const { offer } = yield services.getOffer(offerId);
    if (offer) yield put(actions.getOfferSuccess({ offer }));
  } catch (err) {
    yield put(actions.getOfferFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getOfferSaga() {
  yield takeLatest(constants.GET_OFFER_START, getOffer);
}

// Add Offer
function* addOffer({ payload }) {
  try {
    const { offer } = yield services.addOffer(payload);
    if (offer) {
      yield put(actions.addOfferSuccess());
      yield put(renderSnackbar('success', 'Agregado correctamente!'));
      yield put(push('/Offers'));
    }
  } catch (err) {
    yield put(actions.addOfferFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* addOfferSaga() {
  yield takeLatest(constants.ADD_OFFER_START, addOffer);
}

// Edit Offer
function* editOffer({ payload }) {
  try {
    const { offer } = yield services.editOffer(payload);
    if (offer) {
      yield put(actions.editOfferSuccess());
      yield put(renderSnackbar('success', 'Editado correctamente!'));
      yield put(push('/Offers'));
    }
  } catch (err) {
    yield put(actions.editOfferFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editOfferSaga() {
  yield takeLatest(constants.EDIT_OFFER_START, editOffer);
}

// Delete Offer
function* deleteOffer({ payload }) {
  try {
    const { success } = yield services.deleteOffer(payload);
    if (success) {
      yield put(actions.editOfferSuccess());
      yield put(renderSnackbar('success', 'Eliminado correctamente!'));
      yield put(actions.getOffersStart());
    }
  } catch (err) {
    yield put(actions.editOfferFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* deleteOfferSaga() {
  yield takeLatest(constants.DELETE_OFFER_START, deleteOffer);
}

export default function* OffersSaga() {
  yield all([
    fork(getOffersSaga),
    fork(getOfferSaga),
    fork(addOfferSaga),
    fork(editOfferSaga),
    fork(deleteOfferSaga)
  ]);
}
