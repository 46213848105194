import * as constants from './sendsConstants';

export const getSendsStart = payload => ({
  type: constants.GET_SENDS_START
});

export const getSendsSuccess = payload => ({
  type: constants.GET_SENDS_SUCCESS,
  payload
});

export const getSendsFailure = payload => ({
  type: constants.GET_SENDS_FAILURE,
  payload
});

// Get one
export const getSendStart = payload => ({
  type: constants.GET_SEND_START,
  payload
});

export const getSendSuccess = payload => ({
  type: constants.GET_SEND_SUCCESS,
  payload
});

export const getSendFailure = payload => ({
  type: constants.GET_SEND_FAILURE,
  payload
});

// Create Send
export const addSendStart = payload => ({
  type: constants.ADD_SEND_START,
  payload
});

export const addSendSuccess = payload => ({
  type: constants.ADD_SEND_SUCCESS,
  payload
});

export const addSendFailure = payload => ({
  type: constants.ADD_SEND_FAILURE,
  payload
});

// Edit Send
export const editSendStart = payload => ({
  type: constants.EDIT_SEND_START,
  payload
});

export const editSendSuccess = payload => ({
  type: constants.EDIT_SEND_SUCCESS,
  payload
});

export const editSendFailure = payload => ({
  type: constants.EDIT_SEND_FAILURE,
  payload
});

// Delete Send
export const deleteSendStart = payload => ({
  type: constants.DELETE_SEND_START,
  payload
});

export const deleteSendSuccess = payload => ({
  type: constants.DELETE_SEND_SUCCESS,
  payload
});

export const deleteSendFailure = payload => ({
  type: constants.DELETE_SEND_FAILURE,
  payload
});
