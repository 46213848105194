import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUsersStart, editUserStart, deleteUserStart } from './usersActions';
// styles & components
import Table from '../../components/Basics/Table';
import Loader from '../../components/Loader/Loader';
import { Container } from './usersStyles';
import {
  TableCell,
  TableRow,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableHeader from '../../components/Basics/TableHeader';
import { useSocket } from 'use-socketio';
import TableButton from '../../components/Basics/TableButton';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

const Users = () => {
  useSocket('NEW_USER', () => dispatch(getUsersStart()));

  const [value, setValue] = useState(''),
    [statusOpen, setStatusOpen] = useState({ open: false, _id: '' }),
    [open, setOpen] = useState({ open: false, _id: '' });

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ users }) => users);

  const history = useHistory();

  useEffect(() => {
    dispatch(getUsersStart());
  }, [dispatch]);

  const handleChangeStatus = useCallback(
    (e, userId) => dispatch(editUserStart({ action: e.target.value, userId })),
    [dispatch]
  );

  const handleChangeRoute = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  const handleDeleteUser = useCallback(() => {
    dispatch(deleteUserStart({ userId: open._id }));
  }, [dispatch, open._id]);

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        buttonText='Usuario'
        onChangeRoute={() => history.push('/users/add')}
        setValue={setValue}
        dispatchTo='users'
      />
      <Container>
        <Table columns={TABLE_OPTIONS.users}>
          {list.map(({ _id, email, role, status, createdAt, last_login }) => (
            <Fragment key={_id}>
              {email.toLowerCase().includes(value.toLowerCase()) && (
                <TableRow>
                  <TableCell align='center'>{email}</TableCell>
                  <TableCell align='center'>{role}</TableCell>
                  <TableCell align='center'>
                    <FormControl fullWidth>
                      <Select
                        open={statusOpen.open && statusOpen._id === _id}
                        onClose={() => setStatusOpen({ open: false, _id: '' })}
                        onOpen={() => setStatusOpen({ open: true, _id })}
                        defaultValue={status}
                        onChange={e => handleChangeStatus(e, _id)}
                        inputProps={{
                          name: 'status'
                        }}
                      >
                        <MenuItem value='deleted'>Bloqueado</MenuItem>
                        <MenuItem value='pending'>Pendiente</MenuItem>
                        <MenuItem value='confirmed'>Confirmado</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableDate date={createdAt} />
                  <TableDate date={last_login} />
                  <TableButton
                    handleClick={() => handleChangeRoute(`/users/edit/${_id}`)}
                    edit
                    disabled={role === 'user'}
                  />
                  <TableButton
                    handleClick={() => setOpen({ open: true, _id })}
                    edit={false}
                    disabled={role === 'user'}
                  />
                </TableRow>
              )}
            </Fragment>
          ))}
        </Table>
      </Container>
      <ConfirmDialog
        open={open.open}
        title='Eliminar usuario'
        description='¿Desea eliminar este usuario de la plataforma?'
        handleAccept={handleDeleteUser}
        handleClose={() => setOpen({ open: false, _id: '' })}
      />
    </>
  );
};

export default Users;
