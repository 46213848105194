import * as constants from './authConstants';

// login / logout
export const loginStart = payload => ({
  type: constants.LOGIN_START,
  payload
});

export const loginSuccess = payload => ({
  type: constants.LOGIN_SUCCESS,
  payload
});

export const loginFailure = payload => ({
  type: constants.LOGIN_FAILURE,
  payload
});

export const logout = () => ({
  type: constants.LOGOUT
});

// edit my account
export const editMyAccountStart = payload => ({
  type: constants.EDIT_MY_ACCOUNT_START,
  payload
});

export const editMyAccountSuccess = payload => ({
  type: constants.EDIT_MY_ACCOUNT_SUCCESS,
  payload
});

export const editMyAccountFailure = payload => ({
  type: constants.EDIT_MY_ACCOUNT_FAILURE,
  payload
});
