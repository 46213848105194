import * as constants from './tagsConstants';

export const getTagsStart = payload => ({
  type: constants.GET_TAGS_START,
  payload
});

export const getTagsSuccess = payload => ({
  type: constants.GET_TAGS_SUCCESS,
  payload
});

export const getTagsFailure = payload => ({
  type: constants.GET_TAGS_FAILURE,
  payload
});

// get tag
export const getTagStart = payload => ({
  type: constants.GET_TAG_START,
  payload
});

export const getTagSuccess = payload => ({
  type: constants.GET_TAG_SUCCESS,
  payload
});

export const getTagFailure = payload => ({
  type: constants.GET_TAG_FAILURE,
  payload
});

// Add tag
export const addTagStart = payload => ({
  type: constants.ADD_TAG_START,
  payload
});

export const addTagSuccess = payload => ({
  type: constants.ADD_TAG_SUCCESS,
  payload
});

export const addTagFailure = payload => ({
  type: constants.ADD_TAG_FAILURE,
  payload
});

// Edit tag
export const editTagStart = payload => ({
  type: constants.EDIT_TAG_START,
  payload
});

export const editTagSuccess = payload => ({
  type: constants.EDIT_TAG_SUCCESS,
  payload
});

export const editTagFailure = payload => ({
  type: constants.EDIT_TAG_FAILURE,
  payload
});

// Delete tag
export const deleteTagStart = payload => ({
  type: constants.DELETE_TAG_START,
  payload
});

export const deleteTagSuccess = payload => ({
  type: constants.DELETE_TAG_SUCCESS,
  payload
});

export const deleteTagFailure = payload => ({
  type: constants.DELETE_TAG_FAILURE,
  payload
});
