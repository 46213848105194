import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';
import { push } from 'connected-react-router';

import * as constants from './usersConstants';
import * as actions from './usersActions';
import * as services from './usersServices';

function* getUsers({ payload }) {
  try {
    const { users } = yield services.getUsers(payload?.month);
    if (users) yield put(actions.getUsersSuccess({ list: users }));
  } catch (err) {
    yield put(actions.getUsersFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getUsersSaga() {
  yield takeLatest(constants.GET_USERS_START, getUsers);
}

// Get user
function* getUser({ payload: { userId } }) {
  try {
    const { user } = yield services.getUser(userId);
    if (user) yield put(actions.getUserSuccess({ user }));
  } catch (err) {
    yield put(actions.getUserFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getUserSaga() {
  yield takeLatest(constants.GET_USER_START, getUser);
}

// Add user
function* addUser({ payload }) {
  try {
    const { user } = yield services.addUser(payload);
    if (user) {
      yield put(actions.addUserSuccess());
      yield put(renderSnackbar('success', 'Creado correctamente!'));
      yield put(push('/users'));
    }
  } catch (err) {
    yield put(actions.addUserFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* addUserSaga() {
  yield takeLatest(constants.ADD_USER_START, addUser);
}

// Edit user
function* editUser({ payload }) {
  console.log(payload);
  try {
    const { success } = yield services.editUser(payload);
    if (success) {
      yield put(actions.editUserSuccess());
      yield put(renderSnackbar('success', 'Editado correctamente!'));
      if (payload.fromForm) yield put(push('/users'));
      else yield put(actions.getUsersStart());
    }
  } catch (err) {
    yield put(actions.editUserFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editUserSaga() {
  yield takeLatest(constants.EDIT_USER_START, editUser);
}

// Edit user
function* deleteUser({ payload }) {
  try {
    const { success } = yield services.deleteUser(payload);
    if (success) {
      yield put(actions.deleteUserSuccess());
      yield put(renderSnackbar('success', 'Eliminado correctamente!'));
      yield put(actions.getUsersStart());
    }
  } catch (err) {
    yield put(actions.deleteUserFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* deleteUserSaga() {
  yield takeLatest(constants.DELETE_USER_START, deleteUser);
}

export default function* usersSaga() {
  yield all([
    fork(getUsersSaga),
    fork(getUserSaga),
    fork(addUserSaga),
    fork(editUserSaga),
    fork(deleteUserSaga)
  ]);
}
