import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { mixins } from '../../utils/styles';

export const NavBarContainer = styled(AppBar)`
  && {
    z-index: 1201;
    margin-left: ${props => (props.open ? '260px' : '')};
    width: ${props => (props.open ? 'calc(100% - 260px)' : '')};
    ${props => mixins.transition('margin', props.open)};
    ${props => mixins.transition('width', props.open)};
  }
`;

export const ToolbarContent = styled(Toolbar)`
  padding-right: 24px;
`;

export const NavBarButton = styled(IconButton)`
  && {
    display: ${props => (props.open ? 'none' : 'block')};
    margin-left: 12px;
    margin-right: 36px;
  }
`;

export const NavBarTitle = styled(Typography)`
  flex-grow: 1;
`;
