import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getSendStart, editSendStart } from './sendsActions';
import renderSnackbar from '../../utils/snackbar';
import { Grid, TextField, Button } from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';
import Loader from '../../components/Loader/Loader';

const EditSend = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const { send, loading, reqLoading } = useSelector(({ sends }) => sends);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getSendStart({ sendId: id }));
  }, [dispatch, id]);

  const onSubmit = ({ name = '', price }) => {
    if (!name.trim().length) {
      dispatch(renderSnackbar('error', 'Nombre requerido'));
      return false;
    }
    if (price === null || price === undefined) {
      dispatch(renderSnackbar('error', 'Precio requerido'));
      return false;
    }
    dispatch(editSendStart({ sendId: id, name, price }));
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} disabled={reqLoading}>
      <FormWrapper>
        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField
                label='Nombre'
                variant='filled'
                fullWidth
                disabled={reqLoading}
              />
            }
            name='name'
            control={control}
            defaultValue={send?.name}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField
                label='Precio'
                variant='filled'
                fullWidth
                disabled={reqLoading}
              />
            }
            name='price'
            control={control}
            defaultValue={send?.price}
          />
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
            disabled={reqLoading}
          >
            Guardar
          </Button>
        </Grid>
      </FormWrapper>
    </Form>
  );
};

export default EditSend;
