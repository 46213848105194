import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';
import { push } from 'connected-react-router';

import * as constants from './combosConstants';
import * as actions from './combosActions';
import * as services from './combosServices';

function* getCombos({ payload }) {
  try {
    const { combos } = yield services.getCombos(payload?.month);
    if (combos) yield put(actions.getCombosSuccess({ list: combos }));
  } catch (err) {
    yield put(actions.getCombosFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getCombosSaga() {
  yield takeLatest(constants.GET_COMBOS_START, getCombos);
}

// Get one combo
function* getCombo({ payload: { comboId } }) {
  try {
    const { combo } = yield services.getCombo(comboId);
    if (combo) yield put(actions.getComboSuccess({ combo }));
  } catch (err) {
    yield put(actions.getComboFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getComboSaga() {
  yield takeLatest(constants.GET_COMBO_START, getCombo);
}

// Add Combo
function* addCombo({ payload }) {
  try {
    const { combo } = yield services.addCombo(payload);
    if (combo) {
      yield put(actions.addComboSuccess());
      yield put(renderSnackbar('success', 'Creado correctamente!'));
      yield put(push('/packs'));
    }
  } catch (err) {
    yield put(actions.addComboFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* addComboSaga() {
  yield takeLatest(constants.ADD_COMBO_START, addCombo);
}

// Edit combo
function* editCombo({ payload }) {
  try {
    const { combo } = yield services.editCombo(payload);
    if (combo) {
      yield put(actions.editComboSuccess());
      yield put(renderSnackbar('success', 'Editado correctamente!'));
      if (payload?.onlyStatus) yield put(actions.getCombosStart());
      else yield put(push('/packs'));
    }
  } catch (err) {
    yield put(actions.editComboFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editComboSaga() {
  yield takeLatest(constants.EDIT_COMBO_START, editCombo);
}

// Delete
function* deleteCombo({ payload }) {
  try {
    const { success } = yield services.deleteCombo(payload);
    if (success) {
      yield put(actions.deleteComboSuccess());
      yield put(actions.getCombosStart());
    }
  } catch (err) {
    yield put(actions.deleteComboFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* deleteComboSaga() {
  yield takeLatest(constants.DELETE_COMBO_START, deleteCombo);
}

export default function* combosSaga() {
  yield all([
    fork(getCombosSaga),
    fork(getComboSaga),
    fork(addComboSaga),
    fork(editComboSaga),
    fork(deleteComboSaga)
  ]);
}
