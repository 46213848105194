import * as constants from './usersConstants';

export const getUsersStart = payload => ({
  type: constants.GET_USERS_START,
  payload
});

export const getUsersSuccess = payload => ({
  type: constants.GET_USERS_SUCCESS,
  payload
});

export const getUsersFailure = payload => ({
  type: constants.GET_USERS_FAILURE,
  payload
});

// Get user
export const getUserStart = payload => ({
  type: constants.GET_USER_START,
  payload
});

export const getUserSuccess = payload => ({
  type: constants.GET_USER_SUCCESS,
  payload
});

export const getUserFailure = payload => ({
  type: constants.GET_USER_FAILURE,
  payload
});

// Add user
export const addUserStart = payload => ({
  type: constants.ADD_USER_START,
  payload
});

export const addUserSuccess = payload => ({
  type: constants.ADD_USER_SUCCESS,
  payload
});

export const addUserFailure = payload => ({
  type: constants.ADD_USER_FAILURE,
  payload
});

// Edit user
export const editUserStart = payload => ({
  type: constants.EDIT_USER_START,
  payload
});

export const editUserSuccess = payload => ({
  type: constants.EDIT_USER_SUCCESS,
  payload
});

export const editUserFailure = payload => ({
  type: constants.EDIT_USER_FAILURE,
  payload
});

// Delete user
export const deleteUserStart = payload => ({
  type: constants.DELETE_USER_START,
  payload
});

export const deleteUserSuccess = payload => ({
  type: constants.DELETE_USER_SUCCESS,
  payload
});

export const deleteUserFailure = payload => ({
  type: constants.DELETE_USER_FAILURE,
  payload
});
