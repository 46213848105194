import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import renderSnackbar from '../../utils/snackbar';

import * as constants from './tagsConstants';
import * as actions from './tagsActions';
import * as services from './tagsServices';

function* getTags({ payload }) {
  try {
    const { tags } = yield services.getTags(payload?.month);
    if (tags) yield put(actions.getTagsSuccess({ list: tags }));
  } catch (err) {
    yield put(actions.getTagsFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getTagsSaga() {
  yield takeLatest(constants.GET_TAGS_START, getTags);
}

// get tag
function* getTag({ payload: { tagId } }) {
  try {
    const { tag } = yield services.getTag(tagId);
    if (tag) yield put(actions.getTagSuccess({ tag }));
  } catch (err) {
    yield put(actions.getTagFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getTagSaga() {
  yield takeLatest(constants.GET_TAG_START, getTag);
}

// Add Tag
function* addTag({ payload }) {
  try {
    const { tag } = yield services.addTag(payload);
    if (tag) {
      yield put(actions.addTagSuccess());
      yield put(renderSnackbar('success', 'Agregado correctamente!'));
      yield put(push('/tags'));
    }
  } catch (err) {
    yield put(actions.addTagFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* addTagSaga() {
  yield takeLatest(constants.ADD_TAG_START, addTag);
}

// Edit Tag
function* editTag({ payload }) {
  try {
    const { tag } = yield services.editTag(payload);
    if (tag) {
      yield put(actions.editTagSuccess());
      yield put(renderSnackbar('success', 'Editado correctamente!'));
      yield put(push('/tags'));
    }
  } catch (err) {
    yield put(actions.editTagFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* editTagSaga() {
  yield takeLatest(constants.EDIT_TAG_START, editTag);
}

// Delete Tag
function* deleteTag({ payload }) {
  try {
    const { success } = yield services.deleteTag(payload);
    if (success) {
      yield put(actions.editTagSuccess());
      yield put(renderSnackbar('success', 'Eliminado correctamente!'));
      yield put(actions.getTagsStart());
    }
  } catch (err) {
    yield put(actions.editTagFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* deleteTagSaga() {
  yield takeLatest(constants.DELETE_TAG_START, deleteTag);
}

export default function* tagsSaga() {
  yield all([
    fork(getTagsSaga),
    fork(getTagSaga),
    fork(addTagSaga),
    fork(editTagSaga),
    fork(deleteTagSaga)
  ]);
}
