export const GET_TAGS_START = 'app/Tags/GET_TAGS_START';
export const GET_TAGS_SUCCESS = 'app/Tags/GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'app/Tags/GET_TAGS_FAILURE';

export const GET_TAG_START = 'app/Tags/GET_TAG_START';
export const GET_TAG_SUCCESS = 'app/Tags/GET_TAG_SUCCESS';
export const GET_TAG_FAILURE = 'app/Tags/GET_TAG_FAILURE';

export const ADD_TAG_START = 'app/Tags/ADD_TAG_START';
export const ADD_TAG_SUCCESS = 'app/Tags/ADD_TAG_SUCCESS';
export const ADD_TAG_FAILURE = 'app/Tags/ADD_TAG_FAILURE';

export const EDIT_TAG_START = 'app/Tags/EDIT_TAG_START';
export const EDIT_TAG_SUCCESS = 'app/Tags/EDIT_TAG_SUCCESS';
export const EDIT_TAG_FAILURE = 'app/Tags/EDIT_TAG_FAILURE';

export const DELETE_TAG_START = 'app/Tags/DELETE_TAG_START';
export const DELETE_TAG_SUCCESS = 'app/Tags/DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'app/Tags/DELETE_TAG_FAILURE';
