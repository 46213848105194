import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNewsletterStart } from './newsletterActions';
// styles & components
import Table from '../../components/Basics/Table';
import Loader from '../../components/Loader/Loader';
import { Container } from './newsletterStyles';
import { TableCell, TableRow } from '@material-ui/core';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableHeader from '../../components/Basics/TableHeader';

const Newsletter = () => {
  const [value, setValue] = useState('');

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ newsletter }) => newsletter);

  useEffect(() => {
    dispatch(getNewsletterStart());
  }, [dispatch]);

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        addButton={false}
        buttonText='Usuario'
        setValue={setValue}
        dispatchTo='newsletter'
      />
      <Container>
        <Table
          columns={TABLE_OPTIONS.newsletter}
          hasEdit={false}
          hasDelete={false}
        >
          {list.map(({ _id, email, createdAt }) => (
            <Fragment key={_id}>
              {email.toLowerCase().includes(value.toLowerCase()) && (
                <TableRow>
                  <TableCell align='center'>{email}</TableCell>
                  <TableDate date={createdAt} />
                </TableRow>
              )}
            </Fragment>
          ))}
        </Table>
      </Container>
    </>
  );
};

export default Newsletter;
