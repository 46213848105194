import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import renderSnackbar from '../../utils/snackbar';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';
import { editMyAccountStart } from './authActions';

const MyAccount = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const { role: userRole, _id, email } = useSelector(
    ({ auth: { user } }) => user
  );
  const [role, setRole] = useState(userRole);

  const onSubmit = data => {
    if (!data.email.trim().length) {
      dispatch(renderSnackbar('error', 'Email requerido'));
      return false;
    }
    dispatch(editMyAccountStart({ profile: { role }, ...data, userId: _id }));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <Grid item xs={12} sm={12}>
          <Controller
            as={<TextField label='Email' variant='filled' fullWidth disabled />}
            name='email'
            control={control}
            defaultValue={email}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Rol</InputLabel>
            <Select onChange={e => setRole(e.target.value)} value={role}>
              <MenuItem value='manager'>Manager</MenuItem>
              <MenuItem value='admin'>Admin</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField label='Contraseña actual' variant='filled' fullWidth />
            }
            name='oldPassword'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField label='Contraseña nueva' variant='filled' fullWidth />
            }
            name='newPassword'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
          >
            Guardar
          </Button>
        </Grid>
      </FormWrapper>
    </Form>
  );
};

export default MyAccount;
