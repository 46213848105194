import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getHomeStatsStart } from './homeActions';
// styles & components
import { Container } from './homeStyles';
import {
  Paper,
  Grid,
  Button,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import {
  MonetizationOn,
  Receipt,
  People,
  Label,
  Category,
  Toll,
  LocalOffer,
  AccountTree
} from '@material-ui/icons';
import Text from '../../components/Basics/Text';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../components/Loader/Loader';
import { enumMonths } from '../../utils/enum';

import moment from 'moment-timezone/builds/moment-timezone-with-data';
moment.tz('America/Argentina/Buenos_Aires');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center'
  }
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null),
    [month, setMonth] = useState(moment().format('M') - 1);

  const { list, loading } = useSelector(({ home }) => home);

  useEffect(() => {
    dispatch(getHomeStatsStart({ month }));
  }, [dispatch, month]);

  const handleClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelectMonth = ({ target: { value } }) => {
    dispatch(getHomeStatsStart({ month: value.toString() }));
    handleClose();
    setMonth(value);
  };

  if (loading) return <Loader />;

  return (
    <Container className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        {enumMonths().map((month, i) => (
          <MenuItem key={month} value={i} onClick={handleSelectMonth}>
            {month}
          </MenuItem>
        ))}
      </Menu>

      <Grid container spacing={4} justify='center'>
        <Grid item xs={12} sm={8}>
          <Typography>
            01 -{' '}
            {moment()
              ?.month(month)
              ?.endOf('month')
              ?.format('DD [de] MMMM [de] YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant='contained'
            color='secondary'
            size='large'
            onClick={handleClick}
          >
            Cambiar mes
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} direction='row' justify='center'>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <MonetizationOn style={{ fontSize: 70, color: '#00695c' }} />
            <Text value='Total vendido' bold />
            <Text value={`$${list.sold.toFixed(2)}`} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <Receipt style={{ fontSize: 70, color: '#4527a0' }} />
            <Text value='Total ordenes' bold />
            <Text value={list.orders} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <Toll style={{ fontSize: 70, color: '#f9a825' }} />
            <Text value='Total productos' bold />
            <Text value={list.products} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <People style={{ fontSize: 70, color: '#f50057' }} />
            <Text value='Total usuarios' bold />
            <Text value={list.users} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <Label style={{ fontSize: 70, color: '#3d5afe' }} />
            <Text value='Total etiquetas' bold />
            <Text value={list.tags} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <Category style={{ fontSize: 70, color: '#ef6c00' }} />
            <Text value='Total categorías' bold />
            <Text value={list.categories} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <LocalOffer style={{ fontSize: 70, color: '#ad1457' }} />
            <Text value='Total ofertas' bold />
            <Text value={list.offers} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ textAlign: 'center' }} className={classes.paper}>
            <AccountTree style={{ fontSize: 70, color: '#4e342e' }} />
            <Text value='Total combos' bold />
            <Text value={list.combos} />
          </Paper>
        </Grid>
      </Grid>
      {/* End grid container */}
    </Container>
  );
};

export default Home;
