import * as constants from './newsletterConstants';
    
export const getNewsletterStart = payload => ({
  type: constants.GET_NEWSLETTER_START,
  payload
});

export const getNewsletterSuccess = payload => ({
  type: constants.GET_NEWSLETTER_SUCCESS,
  payload
});

export const getNewsletterFailure = payload => ({
  type: constants.GET_NEWSLETTER_FAILURE,
  payload
});
