import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProductsStart } from '../../containers/Products/productsActions';
import { getOrdersStart } from '../../containers/Orders/ordersActions';
import { getTagsStart } from '../../containers/Tags/tagsActions';
import { getCategoriesStart } from '../../containers/Categories/categoriesActions';
import { getUsersStart } from '../../containers/Users/usersActions';
import { getNewsletterStart } from '../../containers/Newsletter/newsletterActions';

import { Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { enumMonths } from '../../utils/enum';
import moment from 'moment';
import { getOffersStart } from '../../containers/Offers/offersActions';
import { getCombosStart } from '../../containers/Combos/combosActions';

const useStyles = makeStyles({
  root: {
    background: '#f50057',
    '&:hover': {
      background: '#f50057'
    },
    color: '#fff',
    marginLeft: '10px'
  }
});

let monthSelected = { text: '', page: '' };

const MonthFilter = ({ dispatchTo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(),
    // eslint-disable-next-line
    [month, setMonth] = useState(''),
    [monthState, setMonthState] = useState('');

  const handleSelectMonth = ({ target: { value } }) => {
    const query = { month: value.toString() };

    if (dispatchTo === 'products') dispatch(getProductsStart(query));
    else if (dispatchTo.includes('orders'))
      dispatch(
        getOrdersStart({
          status: dispatchTo.split('-')[1],
          month: value.toString()
        })
      );
    else if (dispatchTo === 'categories') dispatch(getCategoriesStart(query));
    else if (dispatchTo === 'tags') dispatch(getTagsStart(query));
    else if (dispatchTo === 'users') dispatch(getUsersStart(query));
    else if (dispatchTo === 'newsletter') dispatch(getNewsletterStart(query));
    else if (dispatchTo === 'offers') dispatch(getOffersStart(query));
    else if (dispatchTo === 'combos') dispatch(getCombosStart(query));

    setAnchorEl(null);
    setMonth(value);
    monthSelected.text = moment()
      ?.month(value)
      ?.endOf('month')
      ?.format('[(]MMMM[)]');
    monthSelected.page = dispatchTo;
    setMonthState(monthSelected.text);
  };

  useEffect(() => {
    if (dispatchTo !== monthSelected.page) monthSelected.text = '';
    else setMonthState(monthSelected.text);
  }, [dispatchTo]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        keepMounted
      >
        {enumMonths().map((month, i) => (
          <MenuItem key={month} value={i} onClick={handleSelectMonth}>
            {month}
          </MenuItem>
        ))}
      </Menu>
      <Button
        variant='contained'
        className={classes.root}
        size='medium'
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        Elegir Mes {monthState}
      </Button>
    </>
  );
};

export default React.memo(MonthFilter);
