import * as constants from './notificationsConstants';

const initialState = {
  list: [],
  unreads: [],
  loading: true,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_NOTIFICATIONS_START:
    case constants.READ_NOTIFICATIONS:
      return { ...state, loading: true, unreads: [], error: null };

    case constants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        unreads: payload.list.filter(n => !n.read),
        loading: false,
        error: null
      };

    case constants.GET_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, error: payload.error };

    default:
      return state;
  }
};
