import * as constants from './authConstants';

const initialState = {
  user: {},
  currentUser: {},
  isAuth: false,
  loading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.LOGIN_START:
    case constants.EDIT_MY_ACCOUNT_START:
      return { ...state, loading: true, error: null };

    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        isAuth: !!payload.user,
        loading: false,
        error: null
      };

    case constants.EDIT_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: payload.user,
        loading: false
      };

    case constants.LOGIN_FAILURE:
    case constants.EDIT_MY_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };

    case constants.LOGOUT:
      localStorage.removeItem('token');
      return { ...state, isAuth: false, user: {} };

    default:
      return state;
  }
};
