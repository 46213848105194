import * as constants from './categoriesConstants';

const initialState = {
  list: [],
  category: {},
  loading: true,
  reqLoading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_CATEGORIES_START:
    case constants.GET_CATEGORY_START:
      return { ...state, category: {}, loading: true, error: null };

    case constants.ADD_CATEGORY_START:
    case constants.EDIT_CATEGORY_START:
      return { ...state, reqLoading: true, error: null };

    case constants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };

    case constants.GET_CATEGORY_SUCCESS:
      return { ...state, loading: false, category: payload.category };

    case constants.ADD_CATEGORY_SUCCESS:
    case constants.EDIT_CATEGORY_SUCCESS:
      return { ...state, reqLoading: false, error: null };

    case constants.GET_CATEGORIES_FAILURE:
    case constants.GET_CATEGORY_FAILURE:
    case constants.ADD_CATEGORY_FAILURE:
    case constants.EDIT_CATEGORY_FAILURE:
    case constants.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        reqLoading: false,
        error: payload.error
      };

    default:
      return state;
  }
};
