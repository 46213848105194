import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducers
import authReducer from './containers/Auth/authReducer';
import homeReducer from './containers/Home/homeReducer';
import notifierReducer from './containers/Notifier/notifierReducer';
import productsReducer from './containers/Products/productsReducer';
import ordersReducer from './containers/Orders/ordersReducer';
import categoriesReducer from './containers/Categories/categoriesReducer';
import tagsReducer from './containers/Tags/tagsReducer';
import usersReducer from './containers/Users/usersReducer';
import newsletterReducer from './containers/Newsletter/newsletterReducer';
import offersReducer from './containers/Offers/offersReducer';
import combosReducer from './containers/Combos/combosReducer';
import notificationsReducer from './containers/Notifications/notificationsReducer';
import sendsReducer from './containers/Sends/sendsReducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'isAuth']
};

export default history =>
  combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    home: homeReducer,
    products: productsReducer,
    orders: ordersReducer,
    categories: categoriesReducer,
    tags: tagsReducer,
    offers: offersReducer,
    users: usersReducer,
    newsletter: newsletterReducer,
    combos: combosReducer,
    notifier: notifierReducer,
    notifications: notificationsReducer,
    sends: sendsReducer,
    router: connectRouter(history)
  });
