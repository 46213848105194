import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import theme from '../../theme';

const ChildItem = ({ route, role }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <Fragment key={route.label}>
      <ListItem button onClick={handleOpen}>
        <ListItemIcon>
          <Icon style={{ color: theme.palette.primary.light }}>
            {route.icon}
          </Icon>
        </ListItemIcon>
        <ListItemText primary={route.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {route.child
            .filter(navC => !navC.fake)
            .map(child => {
              if (
                (role === 'owner' && child.hideOwner) ||
                (role === 'manager' && child.hideManager)
              ) {
                return null;
              }

              let childRoute = child.url;

              return (
                <ListItem
                  key={childRoute}
                  component={Link}
                  to={childRoute}
                  button
                >
                  <ListItemIcon>
                    <Icon
                      style={{
                        color: theme.palette.primary.light,
                        marginLeft: '10px'
                      }}
                      fontSize='small'
                    >
                      {child.icon}
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary={child.label} />
                </ListItem>
              );
            })}
        </List>
      </Collapse>
    </Fragment>
  );
};

ChildItem.propTypes = {
  route: PropTypes.object,
  role: PropTypes.string
};

export default React.memo(ChildItem);
