export const GET_OFFERS_START = 'app/Offers/GET_OFFERS_START';
export const GET_OFFERS_SUCCESS = 'app/Offers/GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAILURE = 'app/Offers/GET_OFFERS_FAILURE';

export const GET_OFFER_START = 'app/Offers/GET_OFFER_START';
export const GET_OFFER_SUCCESS = 'app/Offers/GET_OFFER_SUCCESS';
export const GET_OFFER_FAILURE = 'app/Offers/GET_OFFER_FAILURE';

export const ADD_OFFER_START = 'app/Offers/ADD_OFFER_START';
export const ADD_OFFER_SUCCESS = 'app/Offers/ADD_OFFER_SUCCESS';
export const ADD_OFFER_FAILURE = 'app/Offers/ADD_OFFER_FAILURE';

export const EDIT_OFFER_START = 'app/Offers/EDIT_OFFER_START';
export const EDIT_OFFER_SUCCESS = 'app/Offers/EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_FAILURE = 'app/Offers/EDIT_OFFER_FAILURE';

export const DELETE_OFFER_START = 'app/Offers/DELETE_OFFER_START';
export const DELETE_OFFER_SUCCESS = 'app/Offers/DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAILURE = 'app/Offers/DELETE_OFFER_FAILURE';
