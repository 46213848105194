import api from '../../utils/api';

export const getOffers = month => {
  let requestURL = '/offers';

  if (month) requestURL += `?month=${month}`;

  return api(requestURL);
};
export const getOffer = offerId => api(`/offers?offerId=${offerId}`);
export const addOffer = data => api('/offers', 'POST', data);
export const editOffer = data => api('/offers', 'PUT', data);
export const deleteOffer = data => api('/offers', 'DELETE', data);
