import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './homeConstants';
import * as actions from './homeActions';
import * as services from './homeServices';

function* getHomeStats({ payload }) {
  try {
    const stats = yield services.getHomeStats(payload?.month);
    if (stats) yield put(actions.getHomeStatsSuccess({ list: stats }));
  } catch (err) {
    yield put(actions.getHomeStatsFailure({ error: err.message }));
  }
}

function* getHomeStatsSaga() {
  yield takeLatest(constants.GET_HOME_STATS_START, getHomeStats);
}

export default function* homeSaga() {
  yield all([fork(getHomeStatsSaga)]);
}
