import * as constants from './ordersConstants';

export const getOrdersStart = payload => ({
  type: constants.GET_ORDERS_START,
  payload
});

export const getOrdersSuccess = payload => ({
  type: constants.GET_ORDERS_SUCCESS,
  payload
});

export const getOrdersFailure = payload => ({
  type: constants.GET_ORDERS_FAILURE,
  payload
});

// Order
export const getOrderStart = payload => ({
  type: constants.GET_ORDER_START,
  payload
});

export const getOrderSuccess = payload => ({
  type: constants.GET_ORDER_SUCCESS,
  payload
});

export const getOrderFailure = payload => ({
  type: constants.GET_ORDER_FAILURE,
  payload
});

// edit
export const editOrderStart = payload => ({
  type: constants.EDIT_ORDER_START,
  payload
});

export const editOrderSuccess = payload => ({
  type: constants.EDIT_ORDER_SUCCESS,
  payload
});

export const editOrderFailure = payload => ({
  type: constants.EDIT_ORDER_FAILURE,
  payload
});

// edit payment
export const editPaymentStart = payload => ({
  type: constants.EDIT_PAYMENT_START,
  payload
});

export const editPaymentSuccess = payload => ({
  type: constants.EDIT_PAYMENT_SUCCESS,
  payload
});

export const editPaymentFailure = payload => ({
  type: constants.EDIT_PAYMENT_FAILURE,
  payload
});

// refund payment
export const refundPaymentStart = payload => ({
  type: constants.REFUND_PAYMENT_START,
  payload
});

export const refundPaymentSuccess = payload => ({
  type: constants.REFUND_PAYMENT_SUCCESS,
  payload
});

export const refundPaymentFailure = payload => ({
  type: constants.REFUND_PAYMENT_FAILURE,
  payload
});
