import React from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { ButtonWrapper, AddButton } from './basicsStyles';
import MonthFilter from './MonthFilter';

const TableHeader = ({
  buttonText = 'Producto',
  addButton = true,
  searchInput = true,
  setValue,
  onChangeRoute,
  dispatchTo
}) => {
  return (
    <>
      <ButtonWrapper>
        <AddButton>
          {addButton && (
            <Button
              variant='contained'
              color='secondary'
              size='medium'
              onClick={onChangeRoute}
            >
              Agregar {buttonText}
            </Button>
          )}
          {dispatchTo && <MonthFilter dispatchTo={dispatchTo} />}
        </AddButton>

        {searchInput && (
          <TextField
            placeholder='Buscar'
            variant='filled'
            onChange={e => setValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        )}
      </ButtonWrapper>
    </>
  );
};

export default React.memo(TableHeader);
