import React from 'react';
import styled from 'styled-components';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const Container = styled.div`
  height: 100vh;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles({
  root: {
    background: '#2296F3',
    '&:hover': {
      background: '#2296F3'
    },
    color: '#fff',
    fontSize: '14px',
    textTransform: 'capitalize'
  }
});

const NotFound = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Container>
      <Typography
        variant='h4'
        style={{ color: '#202021', fontWeight: 500, margin: '8px' }}
      >
        404
      </Typography>
      <Typography
        variant='h6'
        style={{ fontSize: '17px', fontWeight: 600, margin: '6px' }}
      >
        Página no encontrada.
      </Typography>

      <Typography
        variant='subtitle2'
        gutterBottom
        style={{ margin: '6px 6px 1.5rem' }}
      >
        La página que estabas buscando no fue encontrada.
      </Typography>

      <Button
        variant='contained'
        size='small'
        onClick={() => dispatch(push('/app'))}
        className={classes.root}
        disableElevation
      >
        Regresar
      </Button>
    </Container>
  );
};

export default NotFound;
