import * as constants from './combosConstants';

export const getCombosStart = payload => ({
  type: constants.GET_COMBOS_START,
  payload
});

export const getCombosSuccess = payload => ({
  type: constants.GET_COMBOS_SUCCESS,
  payload
});

export const getCombosFailure = payload => ({
  type: constants.GET_COMBOS_FAILURE,
  payload
});

// get combo
export const getComboStart = payload => ({
  type: constants.GET_COMBO_START,
  payload
});

export const getComboSuccess = payload => ({
  type: constants.GET_COMBO_SUCCESS,
  payload
});

export const getComboFailure = payload => ({
  type: constants.GET_COMBO_FAILURE,
  payload
});

// add combo
export const addComboStart = payload => ({
  type: constants.ADD_COMBO_START,
  payload
});

export const addComboSuccess = payload => ({
  type: constants.ADD_COMBO_SUCCESS,
  payload
});

export const addComboFailure = payload => ({
  type: constants.ADD_COMBO_FAILURE,
  payload
});

// edit combo
export const editComboStart = payload => ({
  type: constants.EDIT_COMBO_START,
  payload
});

export const editComboSuccess = payload => ({
  type: constants.EDIT_COMBO_SUCCESS,
  payload
});

export const editComboFailure = payload => ({
  type: constants.EDIT_COMBO_FAILURE,
  payload
});

// Delete
export const deleteComboStart = payload => ({
  type: constants.DELETE_COMBO_START,
  payload
});

export const deleteComboSuccess = payload => ({
  type: constants.DELETE_COMBO_SUCCESS,
  payload
});

export const deleteComboFailure = payload => ({
  type: constants.DELETE_COMBO_FAILURE,
  payload
});
