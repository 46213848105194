import api from '../../utils/api';

export const getUsers = month => {
  let requestURL = '/users';
  if (month) requestURL += `?month=${month}`;

  return api(requestURL);
};

export const getUser = userId => api(`/users?userId=${userId}`);

export const addUser = data =>
  api('/users/register', 'POST', { ...data, dashboard: true });

export const editUser = data => {
  const method = data.fromForm ? 'PUT' : 'DELETE';
  const url = data.fromForm ? '/users/user' : '/users';
  return api(url, method, data);
};

export const deleteUser = data =>
  api('/users', 'DELETE', { ...data, deleteFromDB: true });
