import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = ({
  open,
  title,
  description,
  handleAccept,
  handleClose
}) => (
  <Dialog
    // disableBackdropClick
    // disableEscapeKeyDown
    open={open}
    onClose={handleClose}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancelar</Button>
      <Button
        onClick={() => {
          handleAccept();
          handleClose();
        }}
        color='secondary'
        autoFocus
      >
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
