export const GET_COMBOS_START = 'app/Combos/GET_COMBOS_START';
export const GET_COMBOS_SUCCESS = 'app/Combos/GET_COMBOS_SUCCESS';
export const GET_COMBOS_FAILURE = 'app/Combos/GET_COMBOS_FAILURE';

export const GET_COMBO_START = 'app/Combos/GET_COMBO_START';
export const GET_COMBO_SUCCESS = 'app/Combos/GET_COMBO_SUCCESS';
export const GET_COMBO_FAILURE = 'app/Combos/GET_COMBO_FAILURE';

export const ADD_COMBO_START = 'app/Combos/ADD_COMBO_START';
export const ADD_COMBO_SUCCESS = 'app/Combos/ADD_COMBO_SUCCESS';
export const ADD_COMBO_FAILURE = 'app/Combos/ADD_COMBO_FAILURE';

export const EDIT_COMBO_START = 'app/Combos/EDIT_COMBO_START';
export const EDIT_COMBO_SUCCESS = 'app/Combos/EDIT_COMBO_SUCCESS';
export const EDIT_COMBO_FAILURE = 'app/Combos/EDIT_COMBO_FAILURE';

export const DELETE_COMBO_START = 'app/Combos/DELETE_COMBO_START';
export const DELETE_COMBO_SUCCESS = 'app/Combos/DELETE_COMBO_SUCCESS';
export const DELETE_COMBO_FAILURE = 'app/Combos/DELETE_COMBO_FAILURE';
