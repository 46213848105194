import React from 'react';
// import QRCode from 'qrcode.react';
import {
  Container,
  Title,
  Order,
  Text,
  // QRContainer,
  // QRText,
  Thanks,
  Logo
} from './ticketStyles';

// This must be a class based component
class Ticket extends React.PureComponent {
  render() {
    const {
      orderId,
      name,
      location,
      postalCode,
      sendMode,
      department
    } = this.props;
    return (
      <Container>
        <Order>{orderId}</Order>
        <Title>Nombre</Title>
        <Text>{name}</Text>
        {!sendMode.includes('Retiro') && (
          <>
            <Title>Ubicación</Title>
            <Text>{location}</Text>
            <Title>Código Postal</Title>
            <Text>{postalCode}</Text>
            {department && (
              <>
                <Title>Departamento</Title>
                <Text>{department}</Text>
              </>
            )}
          </>
        )}
        {/*   
        <QRText>Lee este codigo desde la cámara de tu celular!</QRText>
        <QRContainer>
          <QRCode value='https://viemystiq.com' />
        </QRContainer> */}
        <Thanks>Gracias por tu compra!</Thanks>
        <Logo src='https://viemystiq.com/static/media/logo.312f106a.PNG' />
      </Container>
    );
  }
}

export default Ticket;
