import * as constants from './usersConstants';

const initialState = {
  list: [],
  user: {},
  loading: true,
  reqLoading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_USERS_START:
    case constants.GET_USER_START:
      return { ...state, user: {}, loading: true, error: null };

    case constants.ADD_USER_START:
      return { ...state, reqLoading: true };

    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };

    case constants.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload.user,
        loading: false,
        error: null
      };

    case constants.ADD_USER_SUCCESS:
      return { ...state, reqLoading: false };

    case constants.GET_USERS_FAILURE:
    case constants.GET_USER_FAILURE:
    case constants.ADD_USER_FAILURE:
    case constants.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        reqLoading: true,
        error: payload.error
      };

    default:
      return state;
  }
};
