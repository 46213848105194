import { validateEmail, validatePassword } from '../../utils/validations';

export const validateLogin = ({ email = '', password = '' }) => {
  const emailVal = validateEmail(email);
  const passwordVal = validatePassword(password);

  if (!emailVal.valid) return emailVal;
  if (!passwordVal.valid) return passwordVal;

  return { valid: true };
};
