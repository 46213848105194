import { all, fork } from 'redux-saga/effects';

// sagas
import authSaga from './containers/Auth/authSaga';
import productsSaga from './containers/Products/productsSaga';
import ordersSaga from './containers/Orders/ordersSaga';
import categoriesSaga from './containers/Categories/categoriesSaga';
import tagsSaga from './containers/Tags/tagsSaga';
import usersSaga from './containers/Users/usersSaga';
import newsletterSaga from './containers/Newsletter/newsletterSaga';
import homeSaga from './containers/Home/homeSaga';
import offersSaga from './containers/Offers/offersSaga';
import combosSaga from './containers/Combos/CombosSaga';
import notificationsSaga from './containers/Notifications/notificationsSaga';
import sendsSaga from './containers/Sends/sendsSaga';

export default function* root() {
  yield all([
    fork(homeSaga),
    fork(authSaga),
    fork(productsSaga),
    fork(ordersSaga),
    fork(categoriesSaga),
    fork(tagsSaga),
    fork(offersSaga),
    fork(combosSaga),
    fork(usersSaga),
    fork(newsletterSaga),
    fork(notificationsSaga),
    fork(sendsSaga)
  ]);
}
