export const GET_SENDS_START = 'app/Sends/GET_SENDS_START';
export const GET_SENDS_SUCCESS = 'app/Sends/GET_SENDS_SUCCESS';
export const GET_SENDS_FAILURE = 'app/Sends/GET_SENDS_FAILURE';

export const GET_SEND_START = 'app/Sends/GET_SEND_START';
export const GET_SEND_SUCCESS = 'app/Sends/GET_SEND_SUCCESS';
export const GET_SEND_FAILURE = 'app/Sends/GET_SEND_FAILURE';

export const ADD_SEND_START = 'app/Sends/ADD_SEND_START';
export const ADD_SEND_SUCCESS = 'app/Sends/ADD_SEND_SUCCESS';
export const ADD_SEND_FAILURE = 'app/Sends/ADD_SEND_FAILURE';

export const EDIT_SEND_START = 'app/Sends/EDIT_SEND_START';
export const EDIT_SEND_SUCCESS = 'app/Sends/EDIT_SEND_SUCCESS';
export const EDIT_SEND_FAILURE = 'app/Sends/EDIT_SEND_FAILURE';

export const DELETE_SEND_START = 'app/Sends/DELETE_SEND_START';
export const DELETE_SEND_SUCCESS = 'app/Sends/DELETE_SEND_SUCCESS';
export const DELETE_SEND_FAILURE = 'app/Sends/DELETE_SEND_FAILURE';
