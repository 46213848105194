export const GET_PRODUCTS_START = 'app/Products/GET_PRODUCTS_START';
export const GET_PRODUCTS_SUCCESS = 'app/Products/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'app/Products/GET_PRODUCTS_FAILURE';

export const GET_PRODUCT_START = 'app/Products/GET_PRODUCT_START';
export const GET_PRODUCT_SUCCESS = 'app/Products/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'app/Products/GET_PRODUCT_FAILURE';

export const ADD_PRODUCT_START = 'app/Products/ADD_PRODUCT_START';
export const ADD_PRODUCT_SUCCESS = 'app/Products/ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'app/Products/ADD_PRODUCT_FAILURE';

export const EDIT_PRODUCT_START = 'app/Products/EDIT_PRODUCT_START';
export const EDIT_PRODUCT_SUCCESS = 'app/Products/EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILURE = 'app/Products/EDIT_PRODUCT_FAILURE';

export const DELETE_PRODUCT_START = 'app/Products/DELETE_PRODUCT_START';
export const DELETE_PRODUCT_SUCCESS = 'app/Products/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'app/Products/DELETE_PRODUCT_FAILURE';
