import api from '../../utils/api';

export const getOrders = (status, month) => {
  let requestURL = '/orders';
  if (status) requestURL += `?status=${status}`;
  if (month) requestURL += `&&month=${month}`;

  return api(requestURL);
};

export const getOrder = orderId => api(`/orders/${orderId}`);

export const editOrder = data => api('/orders', 'PUT', data);

export const editPayment = data => api('/payments', 'PUT', data);
export const refundPayment = data => api('/payments/refund', 'PUT', data);
