import * as constants from './productsConstants';

export const getProductsStart = payload => ({
  type: constants.GET_PRODUCTS_START,
  payload
});

export const getProductsSuccess = payload => ({
  type: constants.GET_PRODUCTS_SUCCESS,
  payload
});

export const getProductsFailure = payload => ({
  type: constants.GET_PRODUCTS_FAILURE,
  payload
});

// get product
export const getProductStart = payload => ({
  type: constants.GET_PRODUCT_START,
  payload
});

export const getProductSuccess = payload => ({
  type: constants.GET_PRODUCT_SUCCESS,
  payload
});

export const getProductFailure = payload => ({
  type: constants.GET_PRODUCT_FAILURE,
  payload
});

// add product
export const addProductStart = payload => ({
  type: constants.ADD_PRODUCT_START,
  payload
});

export const addProductSuccess = payload => ({
  type: constants.ADD_PRODUCT_SUCCESS,
  payload
});

export const addProductFailure = payload => ({
  type: constants.ADD_PRODUCT_FAILURE,
  payload
});

// edit product
export const editProductStart = payload => ({
  type: constants.EDIT_PRODUCT_START,
  payload
});

export const editProductSuccess = payload => ({
  type: constants.EDIT_PRODUCT_SUCCESS,
  payload
});

export const editProductFailure = payload => ({
  type: constants.EDIT_PRODUCT_FAILURE,
  payload
});

// Delete
export const deleteProductStart = payload => ({
  type: constants.DELETE_PRODUCT_START,
  payload
});

export const deleteProductSuccess = payload => ({
  type: constants.DELETE_PRODUCT_SUCCESS,
  payload
});

export const deleteProductFailure = payload => ({
  type: constants.DELETE_PRODUCT_FAILURE,
  payload
});
