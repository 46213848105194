import React from 'react';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { ContentTable } from './basicsStyles';

const Table = ({
  columns,
  hasEdit = true,
  hasDelete = true,
  hasOther = false,

  children
}) => {
  return (
    <ContentTable>
      <TableHead>
        <TableRow>
          {columns.map(text => (
            <TableCell key={text} align='center' style={{ fontWeight: 700 }}>
              {text}
            </TableCell>
          ))}
          {hasEdit && <TableCell padding='checkbox' />}
          {hasDelete && <TableCell padding='checkbox' />}
          {Array.from({ length: hasOther }).map((_, i) => (
            <TableCell key={i} padding='checkbox' />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </ContentTable>
  );
};

export default React.memo(Table);
