import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProductsStart,
  deleteProductStart,
  editProductStart
} from './productsActions';
// styles & components
import Table from '../../components/Basics/Table';
import TableButton from '../../components/Basics/TableButton';
import Loader from '../../components/Loader/Loader';
import { Container } from './productsStyles';
import { TableCell, TableRow, Tooltip, IconButton } from '@material-ui/core';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableBoolean from '../../components/Basics/TableBoolean';
import TableHeader from '../../components/Basics/TableHeader';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { CheckCircle, Cancel } from '@material-ui/icons';
import setOffer from '../../utils/setOffer';

const Products = () => {
  const [value, setValue] = useState(''),
    [open, setOpen] = useState({ open: false, _id: '' });

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ products }) => products);

  const history = useHistory();

  useEffect(() => {
    dispatch(getProductsStart());
  }, [dispatch]);

  const outputPrice = useMemo(
    () => (offer, price) => {
      if (offer?._id && offer?.status === 'confirmed') {
        return (
          <p>
            <span style={{ textDecoration: 'line-through', color: 'red' }}>
              <span style={{ color: '#000' }}>${price}</span>
            </span>
            <span>
              {'  '}$
              {setOffer(price, { type: offer.type, amount: offer.amount })}
            </span>
          </p>
        );
      } else {
        return '$' + price;
      }
    },
    []
  );

  const handleChangeRoute = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  const handleDeleteProduct = useCallback(
    () => dispatch(deleteProductStart({ _id: open._id })),
    [dispatch, open._id]
  );

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        setValue={setValue}
        onChangeRoute={() => handleChangeRoute('/products/add')}
        dispatchTo='products'
      />
      <Container>
        <Table columns={TABLE_OPTIONS.products} hasOther={1}>
          {list.map(
            ({
              _id,
              name,
              price,
              category,
              tags,
              offer,
              likes,
              status,
              stock,
              createdAt,
              updatedAt
            }) => (
              <Fragment key={_id}>
                {name.toLowerCase().includes(value.toLowerCase()) && (
                  <TableRow>
                    <TableCell align='center'>{name}</TableCell>
                    <TableCell align='center'>
                      {outputPrice(offer, price)}
                    </TableCell>
                    <TableCell align='center'>{offer?.name || '-'}</TableCell>
                    <TableCell align='center'>
                      {category?.name || '-'}
                    </TableCell>
                    <TableCell align='center'>
                      {tags.length
                        ? tags?.map(tag => tag.name).join(', ')
                        : '-'}
                    </TableCell>
                    <TableCell align='center'>{likes.length}</TableCell>
                    <TableBoolean
                      bool={status}
                      rules={{ match: 'confirmed' }}
                    />
                    <TableBoolean
                      bool={stock.toString()}
                      rules={{ match: 'true' }}
                      isBool
                    />
                    <TableDate date={createdAt} />
                    <TableDate date={updatedAt} />
                    <TableButton
                      handleClick={() =>
                        handleChangeRoute(`/products/edit/${_id}`)
                      }
                      edit
                    />
                    <TableCell align='center' padding='checkbox'>
                      <Tooltip
                        title={
                          status === 'confirmed' ? 'Dar de baja' : 'Dar de alta'
                        }
                      >
                        <IconButton
                          onClick={() =>
                            dispatch(
                              editProductStart({
                                _id,
                                onlyStatus: true,
                                status:
                                  status === 'confirmed'
                                    ? 'deleted'
                                    : 'confirmed'
                              })
                            )
                          }
                        >
                          {status === 'confirmed' ? (
                            <Cancel />
                          ) : (
                            <CheckCircle />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableButton
                      handleClick={() => setOpen({ open: true, _id })}
                      edit={false}
                    />
                  </TableRow>
                )}
              </Fragment>
            )
          )}
        </Table>
      </Container>
      <ConfirmDialog
        open={open.open}
        title='Eliminar producto'
        description='¿Desea eliminar este producto de la plataforma?'
        handleAccept={handleDeleteProduct}
        handleClose={() => setOpen({ open: false, _id: '' })}
      />
    </>
  );
};

export default Products;
