import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}
  html,
  body {
    width: 100%;
    min-height: 100vh;
    font-family: sans-serif;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  fieldset {
    border: none;
  }
  * ,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  #root {
    min-height: 100vh;
  }
`;

export default GlobalStyles;
