import React from 'react';

const Form = ({ onSubmit, children, disabled }) => {
  return (
    <form onSubmit={onSubmit}>
      <fieldset disabled={disabled}>{children}</fieldset>
    </form>
  );
};

export default Form;
