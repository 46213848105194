import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import renderSnackbar from '../../utils/snackbar';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';
import { getUserStart, editUserStart } from './usersActions';
import Loader from '../../components/Loader/Loader';

const EditUser = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const [role, setRole] = useState('');
  const { user, loading } = useSelector(({ users }) => users);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserStart({ userId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (user?.role) setRole(user.role);
  }, [user]);

  const onSubmit = ({ email = '', password = '' }) => {
    if (!email.trim().length) {
      dispatch(renderSnackbar('error', 'Email requerido'));
      return false;
    }
    if (!role) {
      dispatch(renderSnackbar('error', 'Rol requerido'));
      return false;
    }

    dispatch(
      editUserStart({
        userId: id,
        email,
        role,
        password,
        action: user.status,
        fromForm: true
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <Grid item xs={12} sm={12}>
          <Controller
            as={<TextField label='Email' variant='filled' fullWidth />}
            name='email'
            control={control}
            defaultValue={user.email}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Rol</InputLabel>
            <Select onChange={e => setRole(e.target.value)} value={role}>
              <MenuItem value='manager'>Manager</MenuItem>
              <MenuItem value='admin'>Admin</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            as={<TextField label='Contraseña' variant='filled' fullWidth />}
            name='password'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
          >
            Guardar
          </Button>
        </Grid>
      </FormWrapper>
    </Form>
  );
};

export default EditUser;
