import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Loader from '../../components/Loader/Loader';
import { addOfferStart } from './offersActions';
import renderSnackbar from '../../utils/snackbar';
import { getProductsStart } from '../Products/productsActions';
import { getCombosStart } from '../Combos/combosActions';
// select
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// styles
import { Button, FormControl, InputLabel } from '@material-ui/core';
import { validateOffer } from './offersValidations';
import { DatePicker } from '@material-ui/pickers';
import FormWrapper from '../../components/Basics/FormWrapper';
import Form from '../../components/Basics/Form';

const AddOffer = () => {
  const { handleSubmit, control, setValue } = useForm();
  const dispatch = useDispatch();

  const [date, setDate] = useState({ startDate: moment(), endDate: moment() }),
    [productList, setProductList] = useState([]),
    [combosList, setCombosList] = useState([]),
    [type, setType] = useState('');

  const { list: products, loading } = useSelector(({ products }) => products);
  const { list: combos, loading: loadingCombos } = useSelector(
    ({ combos }) => combos
  );
  const reqLoading = useSelector(({ offers }) => offers.reqLoading);

  useEffect(() => {
    dispatch(getProductsStart());
    dispatch(getCombosStart());
  }, [dispatch]);

  const onSubmit = data => {
    const state = {
      ...data,
      type,
      startDate: date.startDate,
      expiredDate: date.endDate,
      products: productList,
      combos: combosList
    };
    const isValid = validateOffer(state);

    if (!isValid.valid) {
      dispatch(renderSnackbar('error', isValid.message));
      return false;
    }

    dispatch(addOfferStart(state));
  };

  if (loading || loadingCombos) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <Grid item xs={12} sm={6}>
          <Controller
            as={<TextField label='Nombre' variant='filled' fullWidth />}
            name='name'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Tipo</InputLabel>
            <Select
              value={type}
              onChange={({ target }) => {
                setType(target.value);
                setValue('amount', '1');
              }}
              variant='filled'
              fullWidth
            >
              <MenuItem value='fixed'>Fijo</MenuItem>
              <MenuItem value='porcentage'>Porcentaje</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Controller
            as={
              <TextField
                type='number'
                label='Monto'
                variant='filled'
                fullWidth
                inputProps={{
                  max: type === 'fixed' ? null : '99',
                  min: type === 'fixed' ? '1' : '1'
                }}
              />
            }
            name='amount'
            control={control}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Productos</InputLabel>
            <Select
              value={productList}
              onChange={({ target }) => setProductList(target.value)}
              variant='filled'
              multiple
              fullWidth
            >
              {products.map(product => (
                <MenuItem
                  key={product._id}
                  value={product._id}
                  disabled={product.offer?.status === 'confirmed'}
                >
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl variant='filled' fullWidth>
            <InputLabel>Combos</InputLabel>
            <Select
              value={combosList}
              onChange={({ target }) => setCombosList(target.value)}
              variant='filled'
              multiple
              fullWidth
            >
              {combos.map(combo => (
                <MenuItem
                  key={combo._id}
                  value={combo._id}
                  disabled={combo.offer?.status === 'confirmed'}
                >
                  {combo.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePicker
            autoOk
            fullWidth
            disableToolbar
            inputVariant='filled'
            format='DD/MM/YYYY'
            label='Fecha Inicio'
            onChange={d => setDate({ ...date, startDate: d })}
            value={date.startDate}
            disabled={reqLoading}
            minDate={new Date()}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePicker
            autoOk
            fullWidth
            disableToolbar
            inputVariant='filled'
            format='DD/MM/YYYY'
            label='Fecha Expiración'
            onChange={d => setDate({ ...date, endDate: new Date(d) })}
            value={date.endDate}
            disabled={reqLoading}
            minDate={new Date()}
          />
        </Grid>

        <Grid item xs={12} sm={8}></Grid>
        <Grid item xs={12} sm={4}>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            size='large'
            fullWidth
            disabled={reqLoading}
          >
            Crear
          </Button>
        </Grid>
      </FormWrapper>
    </Form>
  );
};

export default AddOffer;
