import React from 'react';
import { Grid } from '@material-ui/core';
import { FormContainer } from './basicsStyles';

const FormWrapper = ({ children }) => {
  return (
    <FormContainer>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </FormContainer>
  );
};

export default React.memo(FormWrapper);
