export const GET_USERS_START = 'app/Users/GET_USERS_START';
export const GET_USERS_SUCCESS = 'app/Users/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'app/Users/GET_USERS_FAILURE';

export const GET_USER_START = 'app/Users/GET_USER_START';
export const GET_USER_SUCCESS = 'app/Users/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'app/Users/GET_USER_FAILURE';

export const ADD_USER_START = 'app/Users/ADD_USER_START';
export const ADD_USER_SUCCESS = 'app/Users/ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'app/Users/ADD_USER_FAILURE';

export const EDIT_USER_START = 'app/Users/EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'app/Users/EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'app/Users/EDIT_USER_FAILURE';

export const DELETE_USER_START = 'app/Users/DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'app/Users/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'app/Users/DELETE_USER_FAILURE';
