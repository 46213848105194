import React from 'react';
import { Tooltip, IconButton, TableCell } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const TableButton = ({
  handleClick,
  edit = true,
  external = false,
  disabled = false
}) => {
  return (
    <TableCell align='center' padding='checkbox'>
      <Tooltip
        title={`${
          external ? 'Más información del ' : edit ? 'Editar' : 'Eliminar'
        } registro`}
        disabled={disabled}
      >
        <IconButton onClick={handleClick}>
          {external ? <EyeIcon /> : edit ? <EditIcon /> : <DeleteIcon />}
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export default React.memo(TableButton);
