import React, { useState, useEffect, createRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Add } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import {
  ImageBoxContainer,
  ImageBox,
  Img,
  ImageWrapper,
  DeleteWrapper,
  ImageContainer
} from './productImageUploadStyles';

function ImagesSelector({ open, setOpen, image, setImage }) {
  const [refs, setRefs] = useState([]);

  useEffect(() => {
    setRefs(elRefs =>
      Array(6)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Imágenes</DialogTitle>
        <DialogContent>
          <DialogContentText>Selecciona hasta 6 imágenes</DialogContentText>
          <ImageBoxContainer>
            {Array.from({ length: 6 }).map((_, i) => (
              <ImageContainer key={i}>
                <ImageBox>
                  <input
                    accept='image/*'
                    hidden
                    multiple
                    id={`button-file${i}`}
                    name={`image${i}`}
                    type='file'
                    ref={refs.length ? refs[i] : null}
                    onChange={e => {
                      setImage({
                        ...image,
                        ['file' + i]: e.target.files[0],
                        ['preview' + i]: URL.createObjectURL(e.target.files[0])
                      });
                    }}
                  />
                  <ImageWrapper>
                    {image[`file${i}`] &&
                    (typeof image[`file${i}`] !== 'string'
                      ? image[`file${i}`].name
                      : true) ? (
                      <Img src={image['preview' + i]} alt='' />
                    ) : (
                      <IconButton
                        color='inherit'
                        onClick={() => refs[i].current.click()}
                      >
                        <Add fontSize='large' />
                      </IconButton>
                    )}
                  </ImageWrapper>
                </ImageBox>
                {image[`file${i}`] &&
                  (typeof image[`file${i}`] !== 'string'
                    ? image[`file${i}`].name
                    : true) && (
                    <DeleteWrapper
                      onClick={() => {
                        setImage({
                          ...image,
                          ['file' + i]: {},
                          ['preview' + i]: {}
                        });
                      }}
                    >
                      <ClearIcon />
                    </DeleteWrapper>
                  )}
              </ImageContainer>
            ))}
          </ImageBoxContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='secondary'>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(ImagesSelector);
