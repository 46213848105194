import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getOffersStart, deleteOfferStart } from './offersActions';
import { enumOfferType } from '../../utils/enum';
// styles & components
import Table from '../../components/Basics/Table';
import TableButton from '../../components/Basics/TableButton';
import Loader from '../../components/Loader/Loader';
import { Container } from './offersStyles';
import { TableCell, TableRow } from '@material-ui/core';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableBoolean from '../../components/Basics/TableBoolean';
import TableHeader from '../../components/Basics/TableHeader';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

const Offers = () => {
  const [value, setValue] = useState(''),
    [open, setOpen] = useState({ open: false, _id: '' });

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ offers }) => offers);

  const history = useHistory();

  useEffect(() => {
    dispatch(getOffersStart());
  }, [dispatch]);

  const handleChangeRoute = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  const handleDeleteOffer = useCallback(() => {
    dispatch(deleteOfferStart({ offerId: open._id }));
  }, [dispatch, open._id]);

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        setValue={setValue}
        buttonText='Oferta'
        onChangeRoute={() => handleChangeRoute('/offers/add')}
        dispatchTo='offers'
      />
      <Container>
        <Table columns={TABLE_OPTIONS.offers}>
          {list.map(
            ({
              _id,
              name,
              amount,
              type,
              products,
              combos,
              startDate,
              expiredDate,
              status,
              createdAt,
              updatedAt
            }) => (
              <Fragment key={_id}>
                {name.toLowerCase().includes(value.toLowerCase()) && (
                  <TableRow>
                    <TableCell align='center'>{name}</TableCell>
                    <TableCell align='center'>
                      {type === 'fixed' && '$'}
                      {type === 'fixed' ? amount.toFixed(2) : amount}
                      {type === 'porcentage' && '%'}
                    </TableCell>
                    <TableCell align='center'>{enumOfferType(type)}</TableCell>
                    <TableCell align='center'>
                      {products.length
                        ? products?.map(pr => pr.name).join(', ')
                        : '-'}
                    </TableCell>
                    <TableCell align='center'>
                      {combos.length
                        ? combos?.map(combo => combo.name).join(', ')
                        : '-'}
                    </TableCell>
                    <TableDate date={startDate} noHour />
                    <TableDate date={expiredDate} noHour />
                    <TableBoolean
                      bool={status}
                      rules={{ match: 'confirmed' }}
                    />
                    <TableDate date={createdAt} />
                    <TableDate date={updatedAt} />
                    <TableButton
                      handleClick={() =>
                        handleChangeRoute(`/offers/edit/${_id}`)
                      }
                      edit
                    />
                    <TableButton
                      handleClick={() => setOpen({ open: true, _id })}
                      edit={false}
                    />
                  </TableRow>
                )}
              </Fragment>
            )
          )}
        </Table>
      </Container>
      <ConfirmDialog
        open={open.open}
        title='Eliminar oferta'
        description='¿Desea eliminar este oferta de la plataforma?'
        handleAccept={handleDeleteOffer}
        handleClose={() => setOpen({ open: false, _id: '' })}
      />
    </>
  );
};

export default Offers;
