import api from '../../utils/api';

export const getCategories = month => {
  let requestURL = '/categories';
  if (month) requestURL += `?month=${month}`;

  return api(requestURL);
};
export const getCategory = categoryId =>
  api(`/categories?categoryId=${categoryId}`);
export const addCategory = data => api('/categories', 'POST', data);
export const editCategory = data => api('/categories', 'PUT', data);
export const deleteCategory = data => api('/categories', 'DELETE', data);
