import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export default function SkeletonTypography() {
  return (
    <div style={{ width: '300px', height: '450px' }}>
      <Skeleton variant='rect' width={300} height={90} />
      <Box p={1}></Box>
      <Skeleton variant='rect' width={300} height={90} />
      <Box p={1}></Box>
      <Skeleton variant='rect' width={300} height={90} />
      <Box p={1}></Box>
      <Skeleton variant='rect' width={300} height={90} />
      <Box p={1}></Box>
      <Skeleton variant='rect' width={300} height={90} />
    </div>
  );
}
