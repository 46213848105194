import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './notificationsConstants';
import * as actions from './notificationsActions';
import * as services from './notificationsServices';

function* getNotifications() {
  try {
    const { notifications } = yield services.getNotifications();
    if (notifications)
      yield put(actions.getNotificationsSuccess({ list: notifications }));
  } catch (err) {
    yield put(actions.getNotificationsFailure({ error: err.message }));
  }
}

function* getNotificationsSaga() {
  yield takeLatest(constants.GET_NOTIFICATIONS_START, getNotifications);
}

// Read
function* readNotifications({ payload }) {
  try {
    const { success } = yield services.readNotifications(payload);
    if (success) yield put(actions.getNotificationsStart());
  } catch (err) {
    console.log(err);
    // yield put(actions.getNotificationsFailure({ error: err.message }));
  }
}

function* readNotificationsSaga() {
  yield takeLatest(constants.READ_NOTIFICATIONS, readNotifications);
}

export default function* notificationsSaga() {
  yield all([fork(getNotificationsSaga), fork(readNotificationsSaga)]);
}
