import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
  text-align: center;
`;

export const Title = styled.p`
  font-weight: 700;
`;

export const Order = styled.h1`
  padding: 0.5rem;
`;

export const Text = styled.p`
  padding: 0.5rem 0.5rem 1rem;
  font-size: 14px;
`;

export const QRContainer = styled.p`
  text-align: center;
  padding: 1.3rem;
`;

export const QRText = styled.p`
  padding-top: 0.7rem;
  font-weight: 700;
`;

export const Thanks = styled.h3`
  color: #c99b6f;
`;

export const Logo = styled.img`
  width: 100px;
  margin: 15px;
`;
