export const GET_ORDERS_START = 'app/Orders/GET_ORDERS_START';
export const GET_ORDERS_SUCCESS = 'app/Orders/GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'app/Orders/GET_ORDERS_FAILURE';

export const GET_ORDER_START = 'app/Orders/GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'app/Orders/GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'app/Orders/GET_ORDER_FAILURE';

export const EDIT_ORDER_START = 'app/Orders/EDIT_ORDER_START';
export const EDIT_ORDER_SUCCESS = 'app/Orders/EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'app/Orders/EDIT_ORDER_FAILURE';

export const EDIT_PAYMENT_START = 'app/Orders/EDIT_PAYMENT_START';
export const EDIT_PAYMENT_SUCCESS = 'app/Orders/EDIT_PAYMENT_SUCCESS';
export const EDIT_PAYMENT_FAILURE = 'app/Orders/EDIT_PAYMENT_FAILURE';

export const REFUND_PAYMENT_START = 'app/Orders/REFUND_PAYMENT_START';
export const REFUND_PAYMENT_SUCCESS = 'app/Orders/REFUND_PAYMENT_SUCCESS';
export const REFUND_PAYMENT_FAILURE = 'app/Orders/REFUND_PAYMENT_FAILURE';
