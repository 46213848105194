import * as constants from './productsConstants';

const initialState = {
  list: [],
  product: {},
  loading: true,
  reqLoading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_PRODUCTS_START:
    case constants.GET_PRODUCT_START:
      return { ...state, loading: true, error: null };

    case constants.ADD_PRODUCT_START:
    case constants.EDIT_PRODUCT_START:
      return { ...state, reqLoading: true, error: null };

    case constants.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };
    case constants.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: payload.product,
        loading: false,
        error: null
      };

    case constants.ADD_PRODUCT_SUCCESS:
    case constants.EDIT_PRODUCT_SUCCESS:
      return { ...state, reqLoading: false, error: null };

    case constants.GET_PRODUCTS_FAILURE:
    case constants.GET_PRODUCT_FAILURE:
    case constants.ADD_PRODUCT_FAILURE:
    case constants.EDIT_PRODUCT_FAILURE:
    case constants.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        reqLoading: false,
        error: payload.error
      };

    default:
      return state;
  }
};
