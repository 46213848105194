import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { replace, goBack } from 'connected-react-router';
// components
import NavBar from '../../components/Navbar/Navbar';
import SideBar from '../../components/SideBar/SideBar';
import Notifier from '../Notifier/Notifier';
// redux
import { logout } from '../Auth/authActions';
// styles
import { Container, Content, NavBarSpacer } from './dashboardStyles';
import { SocketIOProvider } from 'use-socketio';
import { getRoute } from '../App/navigation';

const Dashboard = ({ children, path }) => {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(({ auth }) => auth.isAuth),
    { user } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const route = getRoute(location.pathname);

    if (route?.hideManager && user.role === 'manager')
      dispatch(replace('/newsletter'));
  }, [location.pathname, history, user.role, dispatch]);

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  const handleLogout = () => dispatch(logout());

  const handleGoBack = () => dispatch(goBack());

  if (isLoggedIn)
    return (
      <SocketIOProvider url={process.env.REACT_APP_SOCKET_URL}>
        <Container>
          <NavBar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleLogout={handleLogout}
            path={path}
            handleGoBack={handleGoBack}
          />
          <SideBar
            open={open}
            handleDrawerClose={handleDrawerClose}
            role={user.role}
            organizationId={user.organization}
          />
          <Content open={open}>
            <NavBarSpacer />
            {React.cloneElement(children, {
              user: user
            })}
          </Content>
          <Notifier />
        </Container>
      </SocketIOProvider>
    );

  return <Redirect to='/' />;
};

export default Dashboard;
