export const validateProduct = ({
  name = '',
  price = '',
  ingredients = '',
  usage = '',
  description = '',
  stock = ''
}) => {
  if (!name.trim()) return { message: 'Nombre requerido' };
  if (!price) return { message: 'Precio requerido' };
  if (!ingredients.trim()) return { message: 'Ingredientes requeridos' };
  if (!usage.trim()) return { message: 'Uso requerido' };
  if (!description.trim()) return { message: 'Descripción requerida' };
  if (stock === null || stock === undefined)
    return { message: 'Stock requerido' };

  return { valid: true };
};
