import React from 'react';
import PropTypes from 'prop-types';
// material
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
// navigation
import { searchRoute } from '../../containers/App/navigation';
// styles
import {
  NavBarContainer,
  ToolbarContent,
  NavBarButton,
  NavBarTitle
} from './navbarStyles';
import { Tooltip } from '@material-ui/core';
import Notifications from '../../containers/Notifications/Notifications';

const NavBar = ({
  open,
  handleDrawerOpen,
  handleLogout,
  path,
  handleGoBack
}) => {
  return (
    <NavBarContainer color='default' position='absolute' open={open}>
      <ToolbarContent disableGutters={!open}>
        <NavBarButton
          open={open}
          color='inherit'
          aria-label='Open drawer'
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </NavBarButton>
        <Tooltip title='Atrás'>
          <IconButton color='inherit' onClick={handleGoBack}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
        <NavBarTitle component='h1' variant='h6' color='inherit' noWrap>
          {searchRoute(path)}
        </NavBarTitle>

        <Notifications />
        <Tooltip title='Cerrar sesión'>
          <IconButton color='inherit' onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </ToolbarContent>
    </NavBarContainer>
  );
};

NavBar.propTypes = {
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func,
  handleLogout: PropTypes.func,
  path: PropTypes.string,
  handleGoBack: PropTypes.func
};

export default React.memo(NavBar);
