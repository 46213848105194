import * as constants from './offersConstants';

export const getOffersStart = payload => ({
  type: constants.GET_OFFERS_START,
  payload
});

export const getOffersSuccess = payload => ({
  type: constants.GET_OFFERS_SUCCESS,
  payload
});

export const getOffersFailure = payload => ({
  type: constants.GET_OFFERS_FAILURE,
  payload
});

// Get one
export const getOfferStart = payload => ({
  type: constants.GET_OFFER_START,
  payload
});

export const getOfferSuccess = payload => ({
  type: constants.GET_OFFER_SUCCESS,
  payload
});

export const getOfferFailure = payload => ({
  type: constants.GET_OFFER_FAILURE,
  payload
});

// Add
export const addOfferStart = payload => ({
  type: constants.ADD_OFFER_START,
  payload
});

export const addOfferSuccess = payload => ({
  type: constants.ADD_OFFER_SUCCESS,
  payload
});

export const addOfferFailure = payload => ({
  type: constants.ADD_OFFER_FAILURE,
  payload
});

// Edit
export const editOfferStart = payload => ({
  type: constants.EDIT_OFFER_START,
  payload
});

export const editOfferSuccess = payload => ({
  type: constants.EDIT_OFFER_SUCCESS,
  payload
});

export const editOfferFailure = payload => ({
  type: constants.EDIT_OFFER_FAILURE,
  payload
});

// Delete
export const deleteOfferStart = payload => ({
  type: constants.DELETE_OFFER_START,
  payload
});

export const deleteOfferSuccess = payload => ({
  type: constants.DELETE_OFFER_SUCCESS,
  payload
});

export const deleteOfferFailure = payload => ({
  type: constants.DELETE_OFFER_FAILURE,
  payload
});
