import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSendsStart, deleteSendStart } from './sendsActions';
// styles & components
import Table from '../../components/Basics/Table';
import TableButton from '../../components/Basics/TableButton';
import Loader from '../../components/Loader/Loader';
import { Container } from './sendsStyles';
import { TableCell, TableRow } from '@material-ui/core';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableHeader from '../../components/Basics/TableHeader';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

const Sends = () => {
  const [value, setValue] = useState(''),
    [open, setOpen] = useState({ open: false, _id: '' });

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ sends }) => sends);

  const history = useHistory();

  useEffect(() => {
    dispatch(getSendsStart());
  }, [dispatch]);

  const handleDeleteSend = useCallback(() => {
    dispatch(deleteSendStart({ sendId: open._id }));
  }, [dispatch, open._id]);

  const handleChangeRoute = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        buttonText='Envio'
        setValue={setValue}
        onChangeRoute={() => handleChangeRoute('/sends/add')}
      />
      <Container>
        <Table columns={TABLE_OPTIONS.sends}>
          {list.map(({ _id, name, price, createdAt, updatedAt }) => (
            <Fragment key={_id}>
              {name.toLowerCase().includes(value.toLowerCase()) && (
                <TableRow>
                  <TableCell align='center'>{name}</TableCell>
                  <TableCell align='center'>{price}</TableCell>

                  <TableDate date={createdAt} />
                  <TableDate date={updatedAt} />
                  <TableButton
                    handleClick={() => handleChangeRoute(`/sends/edit/${_id}`)}
                    edit
                  />
                  <TableButton
                    handleClick={() => setOpen({ open: true, _id })}
                    edit={false}
                  />
                </TableRow>
              )}
            </Fragment>
          ))}
        </Table>
      </Container>
      <ConfirmDialog
        open={open.open}
        title='Eliminar Envio'
        description='¿Desea eliminar esta envio de la plataforma?'
        handleAccept={handleDeleteSend}
        handleClose={() => setOpen({ open: false, _id: '' })}
      />
    </>
  );
};

export default Sends;
