import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// material
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Close from '@material-ui/icons/Close';
import ChildItem from '../../components/ChildItem/ChildItem';
// others
import navigation from '../../containers/App/navigation';
import theme from '../../theme';
// styles
import { ListWrapper, SideBarNav, ToolbarIcon } from './sideBarStyles';

const SideBar = ({ open, handleDrawerClose, role, restaurantId }) => (
  <SideBarNav variant='permanent' open={open}>
    <ToolbarIcon>
      <IconButton
        style={{ color: theme.palette.primary.light }}
        onClick={handleDrawerClose}
      >
        <Close />
      </IconButton>
    </ToolbarIcon>
    <Divider />
    <ListWrapper>
      {navigation
        .filter(nav => !nav.fake)
        .map(route => {
          if (
            (role === 'owner' && route.hideOwner) ||
            (role === 'manager' && route.hideManager)
          ) {
            return null;
          }

          let link = route.url;

          if (route.child) {
            return (
              <ChildItem
                key={route.label}
                route={route}
                role={role}
                restaurantId={restaurantId}
              />
            );
          }

          return (
            <ListItem key={route.label} component={Link} to={link} button>
              <ListItemIcon>
                <Icon style={{ color: theme.palette.primary.light }}>
                  {route.icon}
                </Icon>
              </ListItemIcon>
              <ListItemText primary={route.label} />
            </ListItem>
          );
        })}
    </ListWrapper>
  </SideBarNav>
);

SideBar.propTypes = {
  open: PropTypes.bool,
  handleDrawerClose: PropTypes.func,
  role: PropTypes.string,
  restaurantId: PropTypes.string
};

export default React.memo(SideBar);
