import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
// styles & components
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  Popover,
  IconButton,
  Badge,
  Typography,
  makeStyles,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { getNotificationsStart } from './notificationsActions';
import { useSocket } from 'use-socketio';
import Sound from '../../assets/swiftly.mp3';
import { readNotifications } from './notificationsActions';
import Placeholder from '../../components/NotificationItem/NotificationSkeleton';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: 300
  }
}));

function renderRow(props) {
  const { data, index, style } = props;
  return (
    <Link
      to={data[index].route}
      style={{ color: data[index]?.read ? '#4a148c' : 'black' }}
    >
      <ListItem button style={style} key={index}>
        <ListItemText
          primary={
            <Typography variant='subtitle2'>{data[index]?.title}</Typography>
          }
          secondary={
            <>
              <Typography component='span' variant='body2'>
                {data[index]?.message}
              </Typography>
              <div>
                <Typography variant='caption' color='primary'>
                  {moment(data[index].createdAt).fromNow()}{' '}
                  {data[index]?.read && '(Leida)'}
                </Typography>
              </div>
            </>
          }
        />
      </ListItem>
    </Link>
  );
}

const Notifications = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const { list, unreads, loading, error } = useSelector(
    ({ notifications }) => notifications
  );

  useEffect(() => {
    dispatch(getNotificationsStart());
  }, [dispatch]);

  useEffect(() => {
    document.title = `Mystiq Backoffice ${
      unreads.length ? '(' + unreads.length + ')' : ''
    }`;
  }, [unreads]);

  useSocket('NEW_NOTIFICATION', () => {
    dispatch(getNotificationsStart());
    const sound = new Audio(Sound);
    sound.play();
  });

  const handleClick = e => setAnchorEl(e.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    if (unreads.length) dispatch(readNotifications({ notifications: unreads }));
  };

  return (
    <>
      <IconButton color='inherit' onClick={handleClick}>
        <Badge color='secondary' badgeContent={unreads.length} showZero>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {loading ? (
          <Placeholder />
        ) : error ? (
          <h3>{error}</h3>
        ) : (
          <div className={classes.root}>
            <FixedSizeList
              height={400}
              width={300}
              itemSize={90}
              itemCount={list.length}
              itemData={list}
            >
              {renderRow}
            </FixedSizeList>
          </div>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
