import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormWrapper from '../../components/Basics/FormWrapper';
import Text from '../../components/Basics/Text';
import { Grid } from '@material-ui/core';
import Loader from '../../components/Loader/Loader';
import Divider from '../../components/Basics/Divider';
import { getOrderStart } from './ordersActions';
import {
  enumPaymentStatus,
  enumOrderStatus,
  enumSendMode
} from '../../utils/enum';

import moment from 'moment-timezone/builds/moment-timezone-with-data';
moment.tz('America/Argentina/Buenos_Aires');

const Order = () => {
  const dispatch = useDispatch();
  const { order, loading } = useSelector(({ orders }) => orders);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getOrderStart({ orderId: id }));
  }, [dispatch, id]);

  const isSend = !order?.payment?.formData.sendMode?.includes('Retiro');

  if (loading || !order) return <Loader />;

  return (
    <FormWrapper alignItems='center'>
      <Grid item xs={12} sm={4}>
        <Text value='Código' bold />
        <Text value={order.code} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Text value='Estado' bold />
        <Text value={enumOrderStatus(order.status)} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Text value='Estado del pago' bold />
        <Text value={enumPaymentStatus(order.payment.status)} />
      </Grid>

      <Divider />

      <Grid item xs={12} sm={8}>
        <Text value='Productos' bold />
        <Text
          value={order?.products
            ?.map(o => o?.name + ` (${o.quantity})`)
            ?.join(', ')}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Text value='Precio' bold />
        <Text value={'$' + order.payment.price.toFixed(2)} />
      </Grid>

      <Divider />

      <Grid item xs={12} sm={4}>
        <Text value='Nombre y apellido' bold />
        <Text
          value={`${order.payment.formData.name} ${order.payment.formData.surname}`}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Text value='DNI' bold />
        <Text value={order.payment.formData.dni} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Text value='Teléfono' bold />
        <Text value={order.payment.formData.phone} />
      </Grid>

      <Divider />
      {isSend && (
        <>
          <Grid item xs={12} sm={4}>
            <Text value='Provincia' bold />
            <Text value={order?.payment?.formData?.location} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Text value='Ciudad' bold />
            <Text value={order?.payment?.formData?.city} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Text value='Dirección' bold />
            <Text value={order?.payment?.formData?.street} />
          </Grid>

          <Divider />

          <Grid item xs={12} sm={4}>
            <Text value='Código postal' bold />
            <Text value={order?.payment?.formData?.postalCode} />
          </Grid>
        </>
      )}

      <Grid item xs={12} sm={4}>
        <Text value='Usuario' bold />
        <Text
          value={order?.payment?.user?.email || order?.payment?.formData?.email}
        />
      </Grid>

      <Grid item xs={12} sm={isSend ? 4 : 8}>
        <Text value='Modo de retiro/envio' bold />
        <Text value={enumSendMode(order.payment.formData.sendMode)} />
      </Grid>

      <Divider />

      <Grid item xs={12} sm={4}>
        <Text value='Oferta/s' bold />
        <Text
          value={
            order?.products.some(p => p?.offer)
              ? order.products
                  .map(p => p?.offer?.name)
                  .filter(e => e)
                  .join(', ')
              : '-'
          }
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Text value='Encargado el' bold />
        <Text
          value={
            moment(order.createdAt).format('DD/MM/YY') +
            ' | ' +
            moment(order.createdAt).format('hh:ss a')
          }
        />
      </Grid>
    </FormWrapper>
  );
};

export default Order;
