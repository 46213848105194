import React from 'react';
import { Typography } from '@material-ui/core';

const Title = ({ value, variant = 'h6', bold = false }) => (
  <Typography
    variant={variant}
    component={variant}
    display='block'
    style={{
      fontWeight: bold ? 700 : 400,
      textAlign: 'center',
      fontSize: bold ? '18px' : '16px'
    }}
  >
    {value}
  </Typography>
);

export default React.memo(Title);
