import api, { requestData } from '../../utils/api';

export const getProducts = month => {
  let requestURL = '/products';

  if (month) requestURL += `?month=${month}`;

  return api(requestURL);
};
export const getProduct = productId => api(`/products?productId=${productId}`);
export const addProduct = data => requestData('/products', 'POST', data);
export const editProduct = data => requestData('/products', 'PUT', data);
export const deleteProduct = data => api('/products', 'DELETE', data);
