import * as constants from './combosConstants';

const initialState = {
  list: [],
  combo: {},
  loading: true,
  reqLoading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_COMBOS_START:
    case constants.GET_COMBO_START:
      return { ...state, loading: true, error: null };

    case constants.ADD_COMBO_START:
    case constants.EDIT_COMBO_START:
      return { ...state, reqLoading: true, error: null };

    case constants.GET_COMBOS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };
    case constants.GET_COMBO_SUCCESS:
      return {
        ...state,
        combo: payload.combo,
        loading: false,
        error: null
      };

    case constants.ADD_COMBO_SUCCESS:
    case constants.EDIT_COMBO_SUCCESS:
      return { ...state, reqLoading: false, error: null };

    case constants.GET_COMBOS_FAILURE:
    case constants.GET_COMBO_FAILURE:
    case constants.ADD_COMBO_FAILURE:
    case constants.EDIT_COMBO_FAILURE:
    case constants.DELETE_COMBO_FAILURE:
      return {
        ...state,
        loading: false,
        reqLoading: false,
        error: payload.error
      };

    default:
      return state;
  }
};
