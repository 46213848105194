import styled from 'styled-components';

export const ImageBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
`;

export const ImageBox = styled.div`
  width: 150px;
  height: 150px;
  border: 3px dotted black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Img = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
`;

export const ImageWrapper = styled.div``;

export const DeleteWrapper = styled.p`
  text-align: center;
  padding: 0.5rem 0.9rem;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
`;

export const ImageContainer = styled.div``;
