import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';

export const Container = styled(Paper)`
  width: 100%;
  overflow-x: auto;
`;

export const ContentTable = styled(Table)`
  min-width: 700px;
`;

export const ButtonWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const AddButton = styled.div``;

export const FormContainer = styled(Paper)`
  width: 100%;
  padding: 24px 16px;
`;

export const Hr = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  margin-top: 10px;
  margin-bottom: 10px;
`;
