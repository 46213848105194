import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getTagsStart, deleteTagStart } from './tagsActions';
// styles & components
import Table from '../../components/Basics/Table';
import TableButton from '../../components/Basics/TableButton';
import Loader from '../../components/Loader/Loader';
import { Container } from './tagsStyles';
import { TableCell, TableRow } from '@material-ui/core';
import { TABLE_OPTIONS } from '../../utils/constants';
import TableDate from '../../components/Basics/TableDate';
import TableHeader from '../../components/Basics/TableHeader';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

const Tags = () => {
  const [value, setValue] = useState(''),
    [open, setOpen] = useState({ open: false, _id: '' });

  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ tags }) => tags);

  const history = useHistory();

  useEffect(() => {
    dispatch(getTagsStart());
  }, [dispatch]);

  const handleDeleteTag = useCallback(() => {
    dispatch(deleteTagStart({ tagId: open._id }));
  }, [dispatch, open._id]);

  const handleChangeRoute = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  if (loading) return <Loader />;
  if (error) return <h1>{error}</h1>;

  return (
    <>
      <TableHeader
        buttonText='Etiqueta'
        setValue={setValue}
        onChangeRoute={() => handleChangeRoute('/tags/add')}
        dispatchTo='tags'
      />
      <Container>
        <Table columns={TABLE_OPTIONS.tags}>
          {list.map(
            ({ _id, name, products, combos, status, createdAt, updatedAt }) => (
              <Fragment key={_id}>
                {name.toLowerCase().includes(value.toLowerCase()) && (
                  <TableRow>
                    <TableCell align='center'>{name}</TableCell>
                    <TableCell align='center'>
                      {products.length
                        ? products.map(p => p.name).join(', ')
                        : '-'}
                    </TableCell>
                    <TableCell align='center'>
                      {combos.length ? combos.map(c => c.name).join(', ') : '-'}
                    </TableCell>
                    <TableDate date={createdAt} />
                    <TableDate date={updatedAt} />
                    <TableButton
                      handleClick={() => handleChangeRoute(`/tags/edit/${_id}`)}
                      edit
                    />
                    <TableButton
                      handleClick={() => setOpen({ open: true, _id })}
                      edit={false}
                    />
                  </TableRow>
                )}
              </Fragment>
            )
          )}
        </Table>
      </Container>
      <ConfirmDialog
        open={open.open}
        title='Eliminar Etiqueta'
        description='¿Desea eliminar esta etiqueta de la plataforma?'
        handleAccept={handleDeleteTag}
        handleClose={() => setOpen({ open: false, _id: '' })}
      />
    </>
  );
};

export default Tags;
